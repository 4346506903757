import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    session: null,

    //simple Alert dialog
    alertData: {
      show: false,
      headerText: 'Alert',
      bodyText: 'This is a simple alert',
      showCancelButton: true,
      confirmAction: () => {},
      cancelAction: () => {},
    },

    lang_filter: null,
    access: [],
    user_type: null,
    temp_user: null,
    brokers: [],
    bestComments: {},
    localUser: false,
    source_id: '',
    source_name: '',
    source_number: '',
    statsData: {},
    statsStructure: {},
    statsBrokers: {},
    statsFollowUp: {
      Meetings: {},
      SalesCommission: {},
      Budget: {},
      CompanyNPS: {},
      BrokerNPS: {},
      SellerNPS: {},
      BuyerNPS: {},
    },
    pdfData: {
      CompanyNPS: {},
      BrokerNPS: {},
      Comments: {},
    },
  },
  actions,
  mutations,
  getters,
};
