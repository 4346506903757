feedback with an indeterminate v-progress-linear.

<template>
  <div>
    <v-container>
      <v-row>
        <!--    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <v-row>-->

        <v-label>SPEKULANT-SMS</v-label>
        <v-container class="borderContainer">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row no-gutters>
                <v-btn @click="showPhone('reg')" style="margin: 5px"
                  >Visa RegSMS</v-btn
                >
                <v-btn @click="showPhone('viewing')" style="margin: 5px"
                  >Visa efter-SMS</v-btn
                >
              </v-row>
              <v-row no-gutters>
                <v-btn style="margin: 5px">Ställ in</v-btn>
                <v-btn style="margin: 5px">Ställ in</v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row> Ställ in lite mer </v-row>
              <v-row>
                <v-switch label="Bra inställning"></v-switch>
              </v-row>
              <v-row>
                <v-switch label="Bra inställning"></v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-label>ÖVRIGA INSTÄLLNINGAR</v-label>
        <v-container class="borderContainer">
          <v-row no-gutters>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row no-gutters>
                <v-btn style="margin: 5px">Ställ in</v-btn>
                <v-btn style="margin: 5px">Ställ in</v-btn>
              </v-row>
              <v-row no-gutters>
                <v-btn style="margin: 5px">Ställ in</v-btn>
                <v-btn style="margin: 5px">Ställ in</v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row> Ställ in lite mer </v-row>
              <v-row>
                <v-switch label="Bra inställning"></v-switch>
              </v-row>
              <v-row>
                <v-switch label="Bra inställning"></v-switch>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <!--   </v-row>
     </v-col>-->
      </v-row>
    </v-container>

    <v-dialog max-width="600px" v-model="dialog">
      <v-card>
        <v-icon @click="dialog = false">close</v-icon>
        <iPhone :url="iframeUrl" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import iPhone from '../components/device/iphone.vue';
import iPhone from '@/components/device/Frame.vue';
export default {
  name: 'Visning-Admin',
  data() {
    return {
      dialog: false,
      iframeUrl: null,
    };
  },

  components: {
    iPhone,
  },
  methods: {
    showPhone(type) {
      this.dialog = true;

      if (type == 'reg') this.iframeUrl = 'https://sms.boostad.net/eKakw/demo';
      else if (type == 'viewing') this.iframeUrl = 'https://bostad.site/3Jq75';
    },
  },
};
</script>
<style>
.compact {
  transform: scale(0.8);
}
</style>
