import Vue from 'vue';
import App from './App.vue';
import router from './router';
import storeInit from './store';
import vuetify from '@/plugins/vuetify';
import './styles/main.scss';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.$cookies.config('30d'); // 30 dagars expireTime på cookies default

Vue.config.productionTip = false;

const store = storeInit();

//fuling spara storen i window objektet TODO
window.MyStore = store;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
