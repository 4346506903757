<template>
  <v-dialog persistent v-model="openDialog" width="600">
    <div>
      <!--     :style="$vuetify.breakpoint.smAndUp ? null : 'margin-right: 25px'"
      :class="{ desktopView: $vuetify.breakpoint.mdAndUp }"-->
      <br />
      <br />

      <!-- <v-card flat style="max-width: 300px; padding: 15px">  :class="[darkMode ? 'borderContainerDark' : 'borderContainer']"-->
      <v-container
        style="max-width: 600px; background-color: var(--main-bg-color)"
      >
        <label class="white--text">LOGGA IN SOM:</label>
        <br />
        <br />
        <v-btn @click="login(item)">INFOPING DEMOMÄKLARE</v-btn>
        <br />
        <br />
        <label class="white--text">MÄKLARKUND:</label>
        <br />
        <br />
        <v-select
          v-if="this.support == 'superagent'"
          outlined
          dense
          class="overflowOverride"
          :items="customers"
          item-text="name"
          item-value="org_key"
          label="Välj kund:"
          v-model="selectedCustomer"
        ></v-select>
        <v-select
          outlined
          dense
          class="overflowOverride"
          :items="offices"
          item-text="name"
          item-value="customerId"
          return-object
          label="Välj kontor:"
          v-model="selectedOffice"
        ></v-select>

        <div>
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-card outlined>
            <!-- </v-card-title>-->

            <v-data-table
              background-color="var(--secondary-bg-color)"
              :headers="headers"
              :items="brokers"
              :search="search"
            >
              <template v-slot:item.actions="{ item }">
                <!-- item-->
                <v-icon small class="mr-2" @click="login(item)"> login </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-container>
      <!--
    <v-dialog v-model="loading">
      <v-card>
        <v-container style="height: 200px; max-width: 200px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Laddar mäklare
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>-->
    </div>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
//import componentsMixin from '@/components/componentsMixin.js';

//import { mapGetters } from 'vuex';
export default {
  name: 'BrokerLogin',
  // mixins: [componentsMixin],
  data() {
    return {
      search: '',
      selectedOffice: '',
      selectedCustomer: '',
      offices: [],

      headers: [
        { text: 'Namn', value: 'name' },
        { text: 'Mobilnr', value: 'what' },
        { text: 'Logga in', value: 'actions', sortable: false },
      ],
      //loading: false,
      org_key: '',
      openDialog: true,

      // obj: new Object(),
    };
  },

  beforeMount() {
    //sätter css hårt eftersom vi inte fått någon customerPrefix än
    this.$vuetify.theme.dark = true;
    document.getElementsByTagName('body')[0].classList.add('ip');
    //  this.$store.dispatch('infopingstore/clear_brokers');
    // this.is_support();
    this.getOffices();
  },

  mounted() {
    //om inte fler kunder och bara ett kontor välj det
    //   if (this.offices && this.offices.length == '1')
    //     this.selectedOffice = this.offices[0];
  },
  watch: {
    selectedOffice() {
      // this.loading = true;

      this.$store.dispatch('infopingstore/clear_brokers');

      const obj = new Object();

      obj.customerId = this.selectedOffice.customerId;
      if (!this.org_key) this.org_key = this.selectedCustomer;

      obj.name = 'share_brokers';
      obj.org_key = this.org_key;

      this.$store.dispatch('infopingstore/fetch_brokers', obj);
    },

    selectedCustomer() {
      this.$store.dispatch('infopingstore/clear_brokers');
      this.org_key = this.selectedCustomer;

      this.getOffices();
    },
  },
  computed: {
    //  ...mapGetters({ customers: 'infopingstore/customers' }),
    customers() {
      var orgs =
        this.$store.getters['infopingstore/access_orgs']('share_brokers');

      return orgs;
    },

    brokers() {
      var arr = new Array();
      var broker_login_brokers = get(
        this.$store.state.infopingstore,
        'brokers',
        []
      );

      if (broker_login_brokers.length > 0) {
        broker_login_brokers.forEach((element) => {
          if (
            element.customers[0].items &&
            element.customers[0].items.length > 0
          ) {
            element.customers[0].items.forEach((broker) => {
              var line = {
                name: broker.name,
                what: broker.what,
                info: broker.info,
              };
              if (!arr.includes(line)) arr.push(line);
            });
          }
        });
        //sortera array med objekt
        arr.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }

      if (arr && arr.length == 0 && this.selectedOffice != '') {
        var line = {
          what: 'Inga mäklare',
        };
        arr.push(line);
      }

      return arr;
    },
    support() {
      return get(this.$store.state.infopingstore, 'user_type', '');
    },
  },
  methods: {
    getOffices() {
      var comp = this.$store.getters['infopingstore/broker_offices'](
        'share_brokers',
        this.org_key
      );

      this.offices = comp;

      if (this.offices && this.offices.length == '1')
        this.selectedOffice = this.offices[0];
    },

    login(item) {
      if (!item && this.support == 'superagent') {
        var item2 = {};
        item2.info = 'A8A93D7C-37CC-4BAE-8819-AE23ACB6DD9B';
        item2.name = 'Lisa Öhrvall';
        item2.what = '+46725218399';

        item = item2;
      }
      var office = this.selectedOffice.name;

      if (!office) office = 'INFOPING AB';
      var user = encodeURIComponent(office + ' - ' + item.name);

      window.open('/?s=' + item.info + '&cookies=false&user=' + user);
      // window.open('/?s=' + item.info + '&cookies=false&user=' + user);
    },
  },
};
</script>
<style scoped>
/* anpassa höjden så att hela texten syns i v-select funkar inte globalt */
.overflowOverride ::v-deep .v-select__selection {
  overflow: visible !important;
}
</style>
