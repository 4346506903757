<template>
  <div>
    <v-card>
      <v-card-title> Skicka sms </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="recipient"
          label="Skriv mobilnummer"
          outlined
        ></v-text-field>
        <v-select
          v-if="this.org == 'EO' && this.officeManager"
          v-model="selectedTemplate"
          outlined
          label="Välj textmall"
          :items="messageTemplate"
        >
        </v-select>
        <br />
        <v-textarea
          label="Meddelande"
          outlined
          full-width
          textarea
          v-model="messageBody"
        >
        </v-textarea>
        Sms:et skickas från ditt mobilnummer.

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Ångra</v-btn>
          <v-btn text @click="sendSMS()">Skicka</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import azureApi from '@/api/azureApi.js';
import mixin from '@/mixin.js';
import get from 'lodash/get';
//import get from 'lodash/get';
export default {
  name: 'SendSMS',
  mixins: [mixin],
  //props: { module: String },
  data() {
    return {
      messageBody: null,
      messageTemplate: ['Grattis'],
      selectedTemplate: null,
      recipient: null,
    };
  },
  components: {},
  watch: {
    selectedTemplate() {
      if (this.selectedTemplate == 'Grattis' && this.org == 'EO') {
        this.messageBody =
          'Grattis på födelsedagen!' +
          '\r\n' +
          'önskar Erik Olsson Fastighetsförmedling';
      } else if (this.selectedTemplate == 'Grattis' && this.org != 'EO') {
        this.messageBody =
          'Grattis på födelsedagen!' + '\r\n' + 'önskar Demomäklaren';
      }
    },
  },
  created() {},
  beforeMount() {},
  async mounted() {
    if (this.org == 'EO') await this.getStructureSMS();
  },
  computed: {
    officeManager() {
      var mgr = get(
        this.$store.state.infopingstore,
        'statsStructure.Companies',
        null
      );

      if (mgr && mgr.length > 0) return true;
      else return false;
    },

    user() {
      return get(this.$store.state.azurestore, 'userInfo.userId', '');
    },
  },
  methods: {
    close() {
      this.messageBody = null;
      this.selectedTemplate = null;
      this.recipient = null;
      this.$emit('closeDialog');
    },

    sendSMS() {
      var params = {
        brokerId: this.user,
        phone_number: this.recipient,
        message: this.messageBody,
      };
      this.$store.dispatch('azurestore/sendMsg', params).then((res) => {
        if (res == 'ok') {
          this.close();
          this.alertDialogNoCancel('Skickat', 'Sms:et har skickats', () =>
            console.log('user ok')
          );
        } else
          this.alertDialogNoCancel(
            'Något gick fel',
            'SMS:et skickades inte. Försök igen igen. Felkod: ' + res,
            () => console.log('user ok')
          );
      });
    },

    async getStructureSMS() {
      var obj = {
        Session: get(this.$store.state.infopingstore, 'session', []),
        Group: 'officemanager',
        OnlyGroup: true,
      };

      var res = await this.$store.dispatch('infopingstore/statsStructure', obj);

      if (res?.status == 0) {
        this.alertDialogNoCancel('Inget svar', 'Felkod: ' + res.error, () =>
          console.log('user ok')
        );
      } else this.officeManager;
    },
  },
};
</script>
<style></style>
