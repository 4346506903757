<template>
  <div class="container">
    <div class="marvel-device iphone8plus silver">
      <div class="top-bar"></div>
      <div class="sleep"></div>
      <div class="volume"></div>
      <div class="camera"></div>
      <div class="sensor"></div>
      <div class="speaker"></div>
      <div class="screen">
        <iframe :src="url" width="100%" height="100%" frameborder="0"> </iframe>
      </div>
      <div class="home"></div>
      <div class="bottom-bar"></div>
    </div>
  </div>
</template>
<script>
//import '../devices.css-master/assets/devices.min.css';
import '@/components/device/devices.css-master/assets/devices.min.css';

export default {
  name: 'Frame',
  props: { url: String },
};
</script>

<style></style>
