<template>
  <v-dialog v-model="temp" max-width="300px">
    <v-card>
      <v-card-title>Välj mäklarsystem</v-card-title>

      <v-card-text class="bodyText"
        >Du finns upplagd i två mäklarsystem, vilka bostäder vill du se?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="onVitecClick">Vitec</v-btn>

        <v-spacer></v-spacer>
        <v-btn text color="var(--main-text-color)" @click="onMspecsClick"
          >Mspecs</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//TABORT SMH

export default {
  name: 'tempAlert',
  // mixins: [all_mixin],

  data() {
    return {
      temp: true,
    };
  },
  mounted() {
    console.log('hejk');
  },
  computed: {},
  methods: {
    onMspecsClick() {
      this.$store.dispatch('azurestore/TempGetUserFromSession', 'Mspecs');
      this.$emit('closeDialog');
    },
    onVitecClick() {
      this.$store.dispatch('azurestore/TempGetUserFromSession', 'Vitec');
      this.$emit('closeDialog');
    },
  },
};
</script>

<style scoped>
.bodyText {
  white-space: pre-wrap;
}

.v-card > .v-card__subtitle,
.v-card > .v-card__text,
.v-card__title,
.v-card__actions {
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
}
</style>
