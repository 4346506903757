export const USER_SESSION = 'USER_SESSION';
export const SHOW_ALERT = 'SHOW_ALERT';
export const LANG_FILTER = 'LANG_FILTER';
export const ACCESS = 'ACCESS';
export const USER_TYPE = 'USER_TYPE';
export const TEMP_USER = 'TEMP_USER';
export const BROKERS = 'BROKERS';
//export const ORG_BRANDING = 'ORG_BRANDING';
//export const BRANDING = 'BRANDING';
export const LOCAL_USER = 'LOCAL_USER';
export const SOURCE_ID = 'SOURCE_ID';
export const SOURCE_NAME = 'SOURCE_NAME';
export const SOURCE_NUMBER = 'SOURCE_NUMBER';
export const BEST_COMMENTS = 'BEST_COMMENTS';
export const STATS_DATA = 'STATS_DATA';
export const STATS_STRUCTURE = 'STATS_STRUCTURE';
export const STATS_BROKERS = 'STATS_BROKERS';
export const STATS_FOLLOW_UP = 'STATS_FOLLOW_UP';
export const PDF_DATA = 'PDF_DATA';
