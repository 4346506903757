import {
  USER_SESSION,
  SHOW_ALERT,
  LANG_FILTER,
  ACCESS,
  USER_TYPE,
  TEMP_USER,
  BROKERS,
  LOCAL_USER,
  SOURCE_ID,
  SOURCE_NAME,
  SOURCE_NUMBER,
  BEST_COMMENTS,
  STATS_DATA,
  STATS_STRUCTURE,
  STATS_BROKERS,
  STATS_FOLLOW_UP,
  PDF_DATA,
} from './types.js';

export default {
  [USER_SESSION](state, session) {
    state.session = session;
  },
  [SHOW_ALERT](state, alertData) {
    state.alertData = alertData;
  },

  [LANG_FILTER](state, payload) {
    state.lang_filter = payload;
  },

  [ACCESS](state, payload) {
    state.access = payload;
  },
  [USER_TYPE](state, payload) {
    state.user_type = payload;
  },
  [TEMP_USER](state, payload) {
    state.temp_user = payload;
  },
  [BROKERS](state, payload) {
    state.brokers = payload;
  },

  [LOCAL_USER](state, payload) {
    state.localUser = payload;
  },
  [SOURCE_ID](state, payload) {
    state.source_id = payload;
  },
  [SOURCE_NAME](state, payload) {
    state.source_name = payload;
  },
  [SOURCE_NUMBER](state, payload) {
    state.source_number = payload;
  },
  [BEST_COMMENTS](state, payload) {
    state.bestComments = payload;
  },
  [STATS_DATA](state, payload) {
    state.statsData = payload;
  },
  [STATS_STRUCTURE](state, payload) {
    state.statsStructure = payload;
  },
  [STATS_BROKERS](state, payload) {
    state.statsBrokers = payload;
  },
  [STATS_FOLLOW_UP](state, payload) {
    if (payload.Meetings) state.statsFollowUp.Meetings = payload.Meetings;
    else if (payload.SalesCommission)
      state.statsFollowUp.SalesCommission = payload.SalesCommission;
    else if (payload.CompanyNPS)
      state.statsFollowUp.CompanyNPS = payload.CompanyNPS;
    else if (payload.BrokerNPS)
      state.statsFollowUp.BrokerNPS = payload.BrokerNPS;
    else if (payload.SellerNPS)
      state.statsFollowUp.SellerNPS = payload.SellerNPS;
    else if (payload.BuyerNPS) state.statsFollowUp.BuyerNPS = payload.BuyerNPS;
  },
  [PDF_DATA](state, payload) {
    if (payload.Comments) state.pdfData.Comments = payload.Comments;
    else if (payload.BrokerNPS) state.pdfData.BrokerNPS = payload.BrokerNPS;
    else if (payload.CompanyNPS) state.pdfData.CompanyNPS = payload.CompanyNPS;
  },
};
