<script>
/*
Denna Vue 2-applikation skapar en Test-sida för att generera en PDF med mäklaromdömen. Här är en översikt av hur appen fungerar:

1. Appen importerar nödvändiga bibliotek och bilder.
2. En Vue-komponent definieras med data, computed properties och metoder.
3. När komponenten monteras, laddas mäklarbilder.
4. Användaren kan välja en mäklarbild(för att testa olika bredder och höjder) och markera citat att inkludera i PDF:en.
5. När användaren klickar på "Generera PDF", skapas en PDF med vald information.
6. PDF:en byggs upp med hjälp av Canvas API, jsPDF, jdpdf-autotable och Montserrat-typsnittet i generatePDF();
7. <template> definierar sidans struktur och utseende.
8. CSS-stilar används för att formatera sidans element.
*/

// Importera nödvändiga bibliotek och resurser
import mixin from '@/mixin.js';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logoSMH from '@/assets/logosmh2.png';
import arrowImageForPointsContainer from '@/assets/pratbubblapil.png';
import get from 'lodash/get';

// Importera mäklarbilder för att enkelt kunna testa olika bilder med olika aspektratio, höjd och bredd

export default {
  props: { broker: Object },
  name: 'createPdf',
  mixins: [mixin],
  data() {
    return {
      // Array för att lagra valda citat
      selectedQuotes: [],
      // Array med mäklarbilder
    };
  },
  watch: {
    broker() {
      this.$store.dispatch('azurestore/clearImageUrl');
    },
  },
  beforeMount() {
    this.$store.dispatch('azurestore/clearImageUrl');
  },
  // Körs när komponenten har monterats i DOM:en
  mounted() {},
  destroyed() {
    //töm bildurl i storen för det är samma för broker som estate
    this.$store.dispatch('azurestore/clearImageUrl');
  },
  computed: {
    npsScore() {
      var score = get(
        this.$store.state.infopingstore,
        'pdfData.CompanyNPS.Nps',
        null
      );
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
    quotes() {
      return get(this.$store.state.infopingstore, 'pdfData.Comments.items', []);
    },
    brokerName() {
      return get(
        this.$store.state.infopingstore,
        'pdfData.Comments.items[0].BrokerName',
        null
      );
    },
    brokerTitle() {
      return 'Reg. Fastighetsmäklare'; //todo titel verkar inte finnas
    },
    office() {
      return get(
        this.$store.state.infopingstore,
        'pdfData.Comments.items[0].CustomerName',
        null
      );
    },
    rating() {
      return get(
        this.$store.state.infopingstore,
        'pdfData.BrokerNPS.items',
        []
      );
    },
    competenceAverage() {
      var result = this.calculateAverageRating(
        'Hur upplevde du mäklarens kompetens?'
      );
      //avrunda
      var rounded = Math.round(result * 10) / 10;
      return rounded;
    },

    engagementAverage() {
      var result = this.calculateAverageRating(
        'Hur upplevde du mäklarens engagemang?'
      );
      //avrunda
      var rounded = Math.round(result * 10) / 10;
      return rounded;
    },

    brokerImageUrl() {
      var brokerImg = get(this.$store.state.azurestore, 'imgUrl', null);
      if (!brokerImg && this.broker) {
        var params = {
          org: this.org,
          source: this.theSource,
          brokerId: this.broker.Id,
        };
        this.$store.dispatch('azurestore/getBrokerImage', params);
      }

      return get(this.$store.state.azurestore, 'imgUrl', null);
    },
  },
  methods: {
    // Funktion för att räkna ut snittbetyg för en specifik fråga
    calculateAverageRating(question) {
      if (!this.rating || this.rating.length === 0) return 0; // Hanterar om broke är tomt
      const filteredRatings = this.rating.filter(
        (item) => item.Question === question
      );
      if (filteredRatings.length === 0) return 0; // Hanterar om inga betyg finns för frågan
      const totalRatings = filteredRatings.reduce(
        (sum, item) => sum + item.Rating,
        0
      );
      const averageRating = totalRatings / filteredRatings.length;
      return averageRating;
    },
    // Metod för att generera PDF
    async generatePDF() {
      // Skapa ett nytt jsPDF-objekt
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      // Definiera marginaler och sidbredd, i millimeter
      const margin = 20;
      const pageWidth = 210;

      // Lägg till Montserrat-typsnittet
      doc.addFont('fonts/smh/Montserrat-Light.ttf', 'Montserrat', 'normal');
      doc.addFont(
        'fonts/smh/Montserrat-SemiBold.ttf',
        'Montserrat',
        'semibold'
      );
      doc.addFont('fonts/smh/Montserrat-Bold.ttf', 'Montserrat', 'bold');

      // Sätt bakgrundsfärg för övre delen av sidan
      doc.setFillColor(234, 231, 228);
      doc.rect(0, 0, pageWidth, 100.25, 'F');

      // Lägg till rubrik
      doc.setFont('Montserrat', 'semibold');
      doc.setFontSize(27);
      doc.setTextColor(71, 71, 74);
      doc.text('MINA KUNDOMDÖMEN', margin + 7.5, 25, { charSpace: 0.15 });

      // Lägg till logotyp
      const logoImg = new Image();
      logoImg.src = logoSMH;
      const logoWidth = 21.868;
      const logoHeight = logoWidth * (519 / 450);
      const logoX = 179;
      doc.addImage(logoImg, 'PNG', logoX, 11, logoWidth, logoHeight);

      // Lägg till mäklarbild
      const brokerImgSrc = get(this.$store.state.azurestore, 'imgUrl', null);

      // Skala bilden till diameter 55 och facePosition 0.1, alltså ansiktet är 10% från toppen
      const circularImage = await this.drawCircularImage(brokerImgSrc, 55, 0.1); // Justera facePosition efter behov

      const brokerImageWidth = 55;
      // Använd samma värde som bredden eftersom bilden är cirkulär efter skalning i drawCircularImage
      const brokerImageHeight = 55;

      const imageX = margin + 4;
      const imageY = 39;
      doc.addImage(
        circularImage,
        'PNG',
        imageX,
        imageY,
        brokerImageWidth,
        brokerImageHeight
      );

      // Lägg tillbaka Mäklarnamn, Titel, Område och streck
      doc.setFont('Montserrat', 'semibold');
      doc.setFontSize(17);
      doc.text(this.brokerName, margin + 66, 45, {
        charSpace: 0.15,
      });

      doc.setFont('Montserrat', 'normal');
      doc.setFontSize(9);
      doc.text(this.brokerTitle, margin + 66, 51, {
        charSpace: 0.15,
      });
      doc.text(this.office, margin + 66, 55, {
        charSpace: 0.15,
      });

      // Lägg till streck under mäklarinfo
      doc.setDrawColor(66, 66, 74);
      doc.line(margin + 66, 59, pageWidth - margin - 6, 59);

      // Funktion för att lägga till poängcontainer med pil
      const addRating = (text, value, x, y, width) => {
        const height = 8.5; // Behållarens höjd
        const radius = 2; // Radie för avrundade hörn
        const bubblePadding = 1; // Padding mellan texten och kanten på containern
        const spaceBetweenTextAndValue = 4; // Avstånd mellan rubriken och poängen

        // Hantera pilbilden separat, och rita den först
        const arrowImg = new Image();
        arrowImg.src = arrowImageForPointsContainer;

        return new Promise((resolve, reject) => {
          arrowImg.onload = () => {
            // Sätt bredden och beräkna höjden för att behålla aspect ratio
            const arrowWidth = 4;
            const aspectRatio = arrowImg.height / arrowImg.width;
            const arrowHeight = arrowWidth * aspectRatio; // Beräkna höjden baserat på bredden och aspect ratio

            // Lägg till pilen med beräknad höjd och bredd, pilen ritas först
            doc.addImage(
              arrowImg,
              'PNG',
              x + 1.5,
              y + height - 1, // Justera Y-position för pilen baserat på dess höjd
              arrowWidth,
              arrowHeight
            );

            // Rita poängcontainern ovanpå pilen
            doc.setFillColor(71, 34, 117); // Lila färg
            doc.roundedRect(x, y, width, height, radius, radius, 'F');

            // Vertikal centrering för rubrikerna (Kompetens, NPS, Engagemang)
            doc.setFont('Montserrat', 'normal'); // Regular Montserrat för rubriker
            doc.setFontSize(11); // Standard textstorlek
            doc.setTextColor(255, 255, 255); // Vit text

            // Placera rubriken vänsterställd
            const textX = x + bubblePadding + 3;
            const textY = y + height / 2 + 0.1; // Justera för korrekt vertikal centrering
            doc.text(text, textX, textY, { baseline: 'middle', align: 'left' });

            // Vertikal centrering för poängen och placera till höger med mellanrum
            doc.setFont('Montserrat', 'semibold'); // SemiBold Montserrat för poäng
            const textWidth = doc.getTextWidth(text);
            const valueX = textX + textWidth + spaceBetweenTextAndValue;
            doc.text(value, valueX, textY, {
              baseline: 'middle',
              align: 'left',
            });

            resolve(); // När allt är klart, lös promisen
          };

          arrowImg.onerror = reject; // Om det uppstår ett problem med bildladdningen
        });
      };

      // Hämta poäng från JSON
      const competence = this.competenceAverage.toString();
      const NPS = this.npsScore.toString();
      const engagement = this.engagementAverage.toString();

      // Skapa poängcontainrarna med följande parametrar:
      // addRating(text, value, x-position (horisontell), y-position (vertikal), width (bredd på containern))
      await addRating('Kompetens', competence, margin + 66, 65, 45);
      await addRating('NPS', NPS, margin + 118, 65, 35);
      await addRating('Engagemang', engagement, margin + 66, 80, 52);

      // Lägg till citat
      doc.setTextColor(71, 71, 74);
      let yOffset = 115; // Startpositionen
      const leftMargin = margin + 10; // Vänstermarginal
      const rightMargin = margin + 10; // Högermarginal

      this.selectedQuotes.forEach((quote, index) => {
        doc.setFontSize(9);
        doc.setFont('Montserrat', 'normal');

        // Lägg till citattecken runt citatet
        const quoteWithQuotes = `"${quote.Answer}"`;

        // Använd vänster- och högermarginal för att räkna ut tillgänglig bredd
        const availableWidth = pageWidth - leftMargin - rightMargin;

        // Dela upp citatet i rader med den tillgängliga bredden
        const splitQuote = doc.splitTextToSize(quoteWithQuotes, availableWidth);

        // Lägg till citatet
        doc.text(splitQuote, leftMargin, yOffset);

        // Få textdimensionerna för citatet
        const quoteDimensions = doc.getTextDimensions(splitQuote);

        // Justera yOffset beroende på höjden av citatet
        yOffset += quoteDimensions.h;

        // Lägg till lite mellanrum mellan citatet och rollen
        yOffset += 2; // Justera detta värde efter behov

        // Lägg till rollen (Säljare/Köpare och adressen) direkt under citatet
        doc.setFontSize(8);
        doc.setFont('Montserrat', 'semibold');
        const roleText =
          quote.ContactRole === 'Seller' ? 'Säljare på' : 'Köpare på';
        doc.text(`${roleText} ${quote.StreetAddress}`, leftMargin, yOffset);

        // Få textdimensionerna för rollen
        const roleDimensions = doc.getTextDimensions(
          `${roleText} ${quote.StreetAddress}`
        );

        // Justera yOffset beroende på höjden av rollen
        yOffset += roleDimensions.h;

        // Lägg till mellanrum efter varje citatblock
        yOffset += 10; // Justera detta värde efter behov

        // Kontrollera om vi har nått botten av sidan och i så fall skapa en ny sida
        if (yOffset > 280 && index < this.selectedQuotes.length - 1) {
          doc.addPage();
          yOffset = 20; // Startposition för nästa sida
        }
      });

      // Spara PDF
      doc.save('mina_kundomdomen.pdf');

      //logga händelsen på statistikservern
      var obj = {
        organisation: {
          Org: this.org,
        },
        office: {
          CustomerId: get(
            this.$store.state.infopingstore,
            'pdfData.Comments.items[0].CustomerId',
            null
          ),
        },
        broker: {
          BrokerId: this.broker.Id,
        },
        Event: {
          What: 'NpsPdf',
          Type: 'AdminPortal',
        },
      };

      var currentBroker = get(
        this.$store.state.infopingstore,
        'pdfData.CompanyNPS.broker.BrokerName',
        null
      );
      if (this.broker.Name != currentBroker) {
        obj.Event.Extra = currentBroker;
        obj.Event.Why = 'Impersonate';
      }

      this.$store.dispatch('azurestore/postLogging', obj);
    },

    // Funktion för att skapa en cirkulär bild med Canvas API
    // drawCircularImage-funktion med facePosition
    async drawCircularImage(imgSrc, diameter, facePosition = 0.1) {
      return new Promise((resolve, reject) => {
        const scaleFactor = 12; // Skala upp bilden för bättre upplösning
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          const imgWidth = image.width;
          const imgHeight = image.height;
          // Sätt canvasdimensionerna
          const canvasWidth = diameter * scaleFactor;
          const canvasHeight = diameter * scaleFactor;

          canvas.width = canvasWidth;
          canvas.height = canvasHeight;

          // Rita en cirkel som klipper bilden
          context.beginPath();
          context.arc(
            canvasWidth / 2,
            canvasHeight / 2,
            canvasWidth / 2,
            0,
            Math.PI * 2
          );
          context.closePath();
          context.clip();

          // Beräkna skalad bildstorlek för att täcka hela cirkeln
          const imgAspectRatio = imgWidth / imgHeight;
          const canvasAspectRatio = 1; // Canvas är kvadratisk

          let drawWidth, drawHeight;

          if (imgAspectRatio < canvasAspectRatio) {
            // Bilden är högre än canvas
            drawWidth = canvasWidth;
            drawHeight = drawWidth / imgAspectRatio;
          } else {
            // Bilden är bredare än canvas
            drawHeight = canvasHeight;
            drawWidth = drawHeight * imgAspectRatio;
          }

          // Justera drawY för att fokusera på ansiktet
          // facePosition varierar mellan 0 (toppen av bilden) och 1 (botten av bilden)
          const drawX = (canvasWidth - drawWidth) / 2;
          const drawY = (canvasHeight - drawHeight) * facePosition;

          // Rita bilden så att ansiktet är centrerat i cirkeln
          context.drawImage(image, drawX, drawY, drawWidth, drawHeight);

          // Konvertera Canvas till en base64-data med hög upplösning
          resolve(canvas.toDataURL('image/png', 1.0)); // Full kvalitet
        };

        image.onerror = reject;
        image.src = imgSrc;
      });
    },
  },
};
</script>

<template>
  <v-card flat class="cardStyle">
    <!-- Huvudstruktur för appen -->
    <header class="header">
      <!-- Rubrik och mäklarinformation -->
      <h1 class="title">Skapa PDF - Mäklaromdömen</h1>
      <div class="broker-info">
        <div class="image-preview">
          <img
            :src="brokerImageUrl"
            :alt="broker.name"
            :style="{ maxWidth: '200px' }"
          />
        </div>

        <div class="broker-details">
          <h2>{{ brokerName }}</h2>
          <p>{{ brokerTitle }}, {{ office }}</p>
          <div class="ratings">
            <div class="rating">
              <span class="rating-label">Kompetens</span>
              <span class="rating-value">{{ competenceAverage }} / 5</span>
            </div>
            <div class="rating">
              <span class="rating-label">Engagemang</span>
              <span class="rating-value">{{ engagementAverage }} / 5</span>
            </div>
            <div class="rating">
              <span class="rating-label">NPS</span>
              <span class="rating-value">{{ this.npsScore }} / 100</span>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="main-content">
      <!-- Lista med citat och knappar för att generera PDF -->
      <h2 class="section-title">Välj citat att inkludera i PDF:en</h2>
      <button @click="generatePDF" class="generate-btn">Ladda ner PDF</button>
      <ul class="quote-list">
        <li v-for="(quote, index) in quotes" :key="index" class="quote-item">
          <label class="quote-label">
            <input type="checkbox" v-model="selectedQuotes" :value="quote" />
            <div class="quote-content">
              <span class="quote-role">{{
                quote.ContactRole === 'Seller' ? 'Säljare' : 'Köpare'
              }}</span>
              <span class="quote-text">"{{ quote.Answer }}"</span>
              <span class="quote-source">{{ quote.StreetAddress }}</span>
            </div>
          </label>
        </li>
      </ul>
    </main>

    <footer class="footer">
      <button @click="generatePDF" class="generate-btn">Ladda ner PDF</button>
    </footer>
  </v-card>
</template>

<style scoped>
/* CSS-stilar för att formatera appens utseende */

.header {
  background-color: #eae7e4;
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #47474a;
}

.broker-info {
  display: flex;
  align-items: center;
}

.broker-details h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.broker-details p {
  font-size: 14px;
  margin-bottom: 10px;
}

.ratings {
  display: flex;
  gap: 15px;
}

.rating {
  background-color: #472275;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  font-weight: normal;
}

.rating-value {
  font-weight: 600;
  margin-top: 2px;
}

.main-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.quote-list {
  list-style-type: none;
  padding: 0;
}

.quote-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.quote-item:hover {
  background-color: #eae7e4;
}

.quote-label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.quote-label input[type='checkbox'] {
  margin-right: 10px;
  margin-top: 5px;
}

.quote-content {
  display: flex;
  flex-direction: column;
}

.quote-role {
  font-size: 16px;
  font-weight: 600;
  color: #472275;
  margin-bottom: 5px;
}

.quote-text {
  font-style: italic;
  margin-bottom: 5px;
  display: block;
}

.quote-source {
  font-size: 12px;
  color: #666;
}

.footer {
  text-align: center;
}

.generate-btn {
  background-color: #472275;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #5c2d99;
}

.image-preview {
  margin: 10px 50px 10px 10px;
}

.image-preview img {
  max-width: 200px;
  height: auto;
  border: 1px solid #ccc;
}
</style>
