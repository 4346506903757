<template>
  <div style="width: 100%">
    <v-container>
      <v-card flat class="eventCard">
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-row no-gutters>
              <v-label>FÖRSÄLJNING FÖREGÅENDE 12 MÅN</v-label>
            </v-row>
            <br />

            <v-row>
              <div class="mainContainer">
                <div class="spinnerContent">
                  <BarChart
                    :height="220"
                    :data="revenueGraph"
                    :backgroundColor="statsBarColor"
                    :width="500"
                  />
                </div>
                <div v-if="subLoader" class="spinnerOverlay">
                  <Spinner />
                </div>
              </div>
            </v-row>
            <br />
            <br />
            <v-row>
              <table align="left">
                <tr>
                  <th>Försäljning totalt:</th>
                  <td>{{ revenue12months }} kr</td>
                </tr>
                <tr>
                  <th>Antal sålda:</th>
                  <td>{{ estatesSold12 }} st</td>
                </tr>
              </table>
            </v-row>

            <!--barThickness="20"-->
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-row no-gutters>
              <v-label>PROVISION FÖREGÅENDE 12 MÅN</v-label>
            </v-row>
            <br />
            <v-row>
              <div class="mainContainer">
                <div class="spinnerContent">
                  <BarChart
                    :height="220"
                    :data="salesCommission"
                    :backgroundColor="statsBarColor"
                    :width="500"
                  />
                </div>
                <div v-if="subLoader" class="spinnerOverlay">
                  <Spinner />
                </div>
              </div>
            </v-row>
            <br />
            <br />
            <v-row>
              <table>
                <tr>
                  <th>Provision:</th>
                  <td>{{ commission12months }} kr</td>
                </tr>
                <tr>
                  <th>Snittprovision:</th>
                  <td>{{ averageCommission12 }} kr</td>
                </tr>
              </table>
            </v-row>

            <!--barThickness="20"-->
          </v-col>
        </v-row>
      </v-card>

      <br />
      <v-card flat class="eventCard">
        <v-row>
          <v-label>SAMMANFATTNING ANGIVET DATUMINTERVALL</v-label>
        </v-row>
        <br />
        <br />
        <br />
        <v-row>
          <table>
            <tr>
              <th>Provision budget:</th>
              <td>{{ commissionBudget }} kr</td>
              <th>Provision utfall:</th>
              <td>{{ commission }} kr</td>
              <th>Snittprovision:</th>
              <td>{{ averageCommission }} kr</td>
              <th>Försäljning:</th>
              <td>{{ revenue }} kr</td>
            </tr>
            <tr>
              <th>Sålda objekt budget:</th>
              <td>{{ estatesSoldBudget }} st</td>
              <th>Sålda objekt utfall:</th>
              <td>{{ estatesSold }} st</td>
              <th>Möten budget:</th>
              <td>{{ countMeetingsBudget }} st</td>
              <th>Möten utfall:</th>
              <td>{{ countMeetings }} st</td>
            </tr>
            <tr>
              <th>Tips budget:</th>
              <td>{{ countTipsBudget }} st</td>
              <th>Tips utfall:</th>
              <td>{{ countTips }} st</td>
              <th>NPS (företag) säljare:</th>
              <td>{{ npsScoreSeller }}</td>
              <th>NPS (företag) köpare:</th>
              <td>{{ npsScoreBuyer }}</td>
            </tr>
          </table>
        </v-row>
      </v-card>
      <br />
      <v-card flat class="eventCard">
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-row>
              <v-label>OBJEKT PER STATUS</v-label>
            </v-row>
            <br />
            <br />
            <v-row>
              <div>
                <BarChart
                  :height="500"
                  :data="statuses"
                  :backgroundColor="statsBarColor"
                  :width="320"
                  indexAxis="y"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-row>
              <v-label>MÖTEN PER INTAGSKÄLLA</v-label>
            </v-row>
            <br />
            <br />

            <v-row>
              <div>
                <BarChart
                  :height="500"
                  :data="meetings"
                  :width="320"
                  indexAxis="y"
                />
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <br />
      <v-card flat class="eventCard">
        <v-row>
          <v-label>NPS </v-label>
        </v-row>
        <br />
        <br />
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row>
                <p>
                  <v-label>NPS-svar om företaget</v-label>
                </p>
              </v-row>

              <v-row>
                <br />
                <v-label>Totalt antal svar: </v-label>
                <v-label>{{ this.nbrAnswers }}</v-label>
              </v-row>
              <v-row>
                <br />
                <v-label>Ambassadörer: </v-label>
                <v-label>{{ this.nbrPromoters }}</v-label>
              </v-row>
              <br />
              <v-row>
                <v-label>Passiva: </v-label>
                <v-label>{{ this.nbrPassives }}</v-label>
                <br />
              </v-row>
              <br />
              <v-row>
                <br />
                <v-label>Kritiker: </v-label>
                <v-label>{{ this.nbrDetractors }}</v-label> <br />
              </v-row>
              <v-row>
                <br />
                <b>
                  <v-label>NPS-värde: </v-label>
                  <v-label>{{ this.npsScore }}</v-label> <br
                /></b>
              </v-row>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <div>
                <DoughnutChart
                  :width="200"
                  :height="200"
                  :data="chartDataNPS"
                  :backgroundColor="NPSColor"
                />
              </div>
            </v-col>
          </v-col>

          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-row>
                <p>
                  <v-label>NPS-svar om mäklaren</v-label>
                </p>
              </v-row>

              <v-row>
                <br />
                <v-label>Totalt antal svar: </v-label>
                <v-label>{{ this.nbrAnswersBroker }}</v-label>
              </v-row>
              <v-row>
                <br />
                <v-label>Ambassadörer: </v-label>
                <v-label>{{ this.nbrPromotersBroker }}</v-label>
              </v-row>
              <br />
              <v-row>
                <v-label>Passiva: </v-label>
                <v-label>{{ this.nbrPassivesBroker }}</v-label>
                <br />
              </v-row>
              <br />
              <v-row>
                <br />
                <v-label>Kritiker: </v-label>
                <v-label>{{ this.nbrDetractorsBroker }}</v-label> <br />
              </v-row>
              <v-row>
                <br />
                <b>
                  <v-label>NPS-värde: </v-label>
                  <v-label>{{ this.npsScoreBroker }}</v-label> <br
                /></b>
              </v-row>
            </v-col>

            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <div>
                <DoughnutChart
                  :width="200"
                  :height="200"
                  :data="chartDataNPSBroker"
                  :backgroundColor="NPSColor"
                />
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import BarChart from '@/components/charts/BarChartObject.vue';
import DoughnutChart from '@/components/charts/Doughnut.vue';
import Spinner from '@/components/spinners/dualRing.vue';
import get from 'lodash/get';
export default {
  name: 'Events-results',
  // props: { loading: Boolean },
  mixins: [mixin],
  data() {
    return {
      headers: [
        { text: 'Namn', value: 'ContactName' },
        { text: 'Objekt', value: 'StreetAddress' },
        { text: 'Typ', value: 'Type' },
        { text: 'Aktivitet', value: 'What' },
        { text: 'Värde', value: 'Value' },
      ],
      //loadingPage: true,
      currentEvent: [],
      search: '',
      statsBarColor: ['#0000FF'],
      NPSColor: ['#41B883', '#e6b400', '#E46651'], //#41B883', '#E46651', '#00D8FF
      chartDataNPS: [0, 0, 0], //promoters, passives, detractors
      chartDataNPSBroker: [0, 0, 0], //promoters, passives, detractors
    };
  },
  computed: {
    subLoader() {
      return get(this.$store.state.azurestore, 'subLoader', false);
    },
    statuses() {
      var meetings = get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.Statuses',
        []
      );
      return [
        {
          label: 'antal objekt',
          backgroundColor: '#4d207a',
          data: meetings?.map((row) => ({
            x: row.Count,
            y: row.Name,
          })),
        },
      ];
    },
    revenue() {
      var rev = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Revenue',
        '0'
      );

      rev = Math.round(rev);
      rev = rev.toLocaleString('sv');

      return rev;
    },
    revenue12months() {
      var rev = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Revenue12',
        '0'
      );

      rev = Math.round(rev);
      rev = rev.toLocaleString('sv');

      return rev;
    },
    commission() {
      var com = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Commission',
        '0'
      );
      com = Math.round(com);
      com = com.toLocaleString('sv');

      return com;
    },
    commissionBudget() {
      var com = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.BudgetCommission',
        '0'
      );
      com = Math.round(com);
      com = com.toLocaleString('sv');

      return com;
    },
    commission12months() {
      var com = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Commission12',
        '0'
      );
      com = Math.round(com);
      com = com.toLocaleString('sv');

      return com;
    },
    estatesSold() {
      var sales = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.ActualEstatesSold',
        '0'
      );
      sales = sales.toLocaleString('sv');

      return sales;
    },
    estatesSoldBudget() {
      var sales = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.BudgetEstatesSold',
        '0'
      );
      sales = Math.round(sales);
      sales = sales.toLocaleString('sv');

      return sales;
    },
    estatesSold12() {
      var sales = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.ActualEstatesSold12',
        '0'
      );
      sales = sales.toLocaleString('sv');

      return sales;
    },

    salesCommission() {
      var sc = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Sales',
        []
      );

      return [
        {
          label: 'Provision budget',
          backgroundColor: '#dedbd5',
          data: sc?.map((row) => ({
            x: this.getMonthFromDate(row.Month),
            y: this.roundSum(row.BudgetCommission),
          })),
        },
        {
          label: 'Provision utfall',
          backgroundColor: '#4d207a',
          data: sc?.map((row) => ({
            x: this.getMonthFromDate(row.Month),
            y: this.roundSum(row.Commission),
          })),
        },
      ];
    },

    revenueGraph() {
      var sc = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Sales',
        []
      );

      return [
        {
          backgroundColor: '#4d207a',
          label: 'Försäljning',
          data: sc?.map((row) => ({
            x: this.getMonthFromDate(row.Month),
            y: this.roundSum(row.Revenue),
          })),
        },
      ];
    },

    averageCommission() {
      var sales = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.ActualEstatesSold',
        '0'
      );
      var com = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Commission',
        '0'
      );

      var avg = com / sales;
      avg = Math.round(avg);
      avg = avg.toLocaleString('sv');

      return avg;
    },
    averageCommission12() {
      var sales = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.ActualEstatesSold12',
        '0'
      );
      var com = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SalesCommission.Commission12',
        '0'
      );

      var avg = com / sales;
      avg = Math.round(avg);
      avg = avg.toLocaleString('sv');

      return avg;
    },
    meetings() {
      var meetings = get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.Sources',
        []
      );
      return [
        {
          backgroundColor: '#4d207a',
          label: 'antal möten',
          data: meetings?.map((row) => ({
            x: row.Count,
            y: row.Name,
          })),
        },
      ];
    },
    countTips() {
      var sources = get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.Sources',
        []
      );
      var tips = 0;
      sources?.forEach((element) => {
        if (element.Name == 'Tips') tips = element.Count;
      });
      return tips;
    },
    countMeetings() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.ActualMeetingCount',
        '0'
      );
    },
    countMeetingsBudget() {
      var budget = get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.BudgetMeetingCount',
        '0'
      );

      budget = Math.round(budget);
      budget = budget.toLocaleString('sv');
      return budget;
    },
    countTipsBudget() {
      var budget = get(
        this.$store.state.infopingstore,
        'statsFollowUp.Meetings.BudgetTipsCount',
        '0'
      );

      budget = Math.round(budget);
      budget = budget.toLocaleString('sv');
      return budget;
    },
    // test() {
    //   return [
    //     {
    //       label: 'Commission',
    //       backgroundColor: '#f87979',
    //       data: sc?.map((row) => ({
    //         x: row.Month,
    //         y: row.Commission,
    //       })),
    //     },
    //     {
    //       label: 'Revenue',
    //       backgroundColor: '#486659',
    //       data: sc?.map((row) => ({
    //         x: row.Month,
    //         y: row.Revenue,
    //       })),
    //     },
    //   ];
    // },
    nbrAnswers() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.CompanyNPS.Cnt',
        null
      );
    },
    nbrPromoters() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.CompanyNPS.CntPromoters',
        null
      );
    },
    nbrDetractors() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.CompanyNPS.CntDetractors',
        null
      );
    },
    nbrPassives() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.CompanyNPS.CntPassives',
        null
      );
    },
    npsScore() {
      var score = get(
        this.$store.state.infopingstore,
        'statsFollowUp.CompanyNPS.Nps',
        null
      );
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
    nbrAnswersBroker() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.BrokerNPS.Cnt',
        null
      );
    },
    nbrPromotersBroker() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.BrokerNPS.CntPromoters',
        null
      );
    },
    nbrDetractorsBroker() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.BrokerNPS.CntDetractors',
        null
      );
    },
    nbrPassivesBroker() {
      return get(
        this.$store.state.infopingstore,
        'statsFollowUp.BrokerNPS.CntPassives',
        null
      );
    },
    npsScoreBroker() {
      var score = get(
        this.$store.state.infopingstore,
        'statsFollowUp.BrokerNPS.Nps',
        null
      );
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
    npsScoreSeller() {
      var score = get(
        this.$store.state.infopingstore,
        'statsFollowUp.SellerNPS.Nps',
        null
      );
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
    npsScoreBuyer() {
      var score = get(
        this.$store.state.infopingstore,
        'statsFollowUp.BuyerNPS.Nps',
        null
      );
      //avrunda
      var rounded = Math.round(score * 10) / 10;
      return rounded;
    },
  },
  watch: {
    npsScore() {
      this.calculateData();
    },
    npsScoreBroker() {
      this.calculateDataBroker();
    },
    salesCommission() {
      //this.loadingPage = false;
    },
  },
  components: { BarChart, DoughnutChart, Spinner },
  mounted() {},
  methods: {
    getMonthFromDate(date) {
      var d = new Date(date);
      return d.toLocaleString('sv', { month: 'short' });
    },
    roundSum(amount) {
      amount = Math.round(amount);
      return amount;
    },
    calculateData() {
      this.chartDataNPS = [
        this.nbrPromoters,
        this.nbrPassives,
        this.nbrDetractors,
      ];
    },
    calculateDataBroker() {
      this.chartDataNPSBroker = [
        this.nbrPromotersBroker,
        this.nbrPassivesBroker,
        this.nbrDetractorsBroker,
      ];
    },
    // rowClick(item) {
    //   this.currentEvent = item;

    //   this.eventDialog = true;
    // },
    // closeComment() {
    //   this.eventDialog = false;
    //   this.currentEvent = [];
    //   this.commentSurveyType = '';
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eventCard {
  width: 100%;
  padding: 30px;
}

.eventDialog {
  padding: 20px;
}
th {
  text-align: left;
  width: 180px;
  padding-bottom: 5px;
}
td {
  text-align: left;
  width: 180px;
  padding-bottom: 5px;
}
</style>
