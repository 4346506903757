import { mapGetters } from 'vuex';
import { COOKIE_NAME_SESSION } from '@/constants.js';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      org: 'azurestore/org',
      org_key: 'azurestore/org_key',
      customerPrefix: 'azurestore/customerPrefix',
      theSource: 'azurestore/source',
    }),
  },
  methods: {
    getLang(c, w) {
      try {
        var filtered = this.$store.state.infopingstore.lang_filter.items.filter(
          (x) => x.controller == c && x.what == w
        );

        return filtered[0].text;
      } catch {
        return w;
      }
    },

    default_obj() {
      const obj = new Object();
      const Q = new Object();
      obj.Q = Q;
      return obj;
    },
    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
    logout() {
      this.$cookies.remove(COOKIE_NAME_SESSION);

      //this.$store.dispatch("infopingstore/addUserSession", null);
      this.$store.dispatch('infopingstore/clear_all');
      this.$store.dispatch('azurestore/clear_all');
      //   window.location.href=window.location.origin;
      window.location.href = '/';
    },

    supportInfo() {
      this.alertDialogNoCancel(
        'Boostad Support',
        '018-681622' + '\r\n' + 'support@boostad.net',
        () => console.log('user ok')
      );
    },
  },
};
