<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />
      <EstateList module="brokerWeb" />
    </v-container>
    <!--//TABORT SMH-->
    <ChooseBrokerSystem
      v-if="brokerSystem"
      @closeDialog="brokerSystem = false"
    />
  </div>
</template>

<script>
//import azureApi from '@/api/azureApi.js';
import mixin from '@/mixin.js';
import EstateList from '@/components/moduleButtons/estateList.vue';
//TABORT SMH
import ChooseBrokerSystem from '@/components/dialogs/tempDialog.vue';
import { mapGetters } from 'vuex';
import Description from '@/components/description.vue';
import get from 'lodash/get';
export default {
  name: 'Minasidor-Admin',
  mixins: [mixin],

  data() {
    return {
      header: 'Administrera Mina sidor',
      description: 'Välj en bostad.',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_phonecomputer.jpg',
      brokerID: '',
      brokerSystem: false,
    };
  },
  components: { Description, EstateList, ChooseBrokerSystem },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {
    this.$store.dispatch('azurestore/clearImageUrl');
    //TABORT SMH
    var customerId = get(
      this.$store.state.azurestore,
      'userInfo.customerId',
      ''
    );

    if (
      customerId == 'M13858' ||
      customerId == 'M24710' ||
      customerId == 'M25306' ||
      customerId == 'M16469' ||
      customerId == 'M28648' ||
      customerId == 'M14765' ||
      customerId == 'M16111'
    )
      this.brokerSystem = true;
  },
  computed: {
    ...mapGetters({
      customerPrefix: 'azurestore/customerPrefix',
    }),
  },
  methods: {},
};
</script>
<style></style>
