<template>
  <div class="subHeader">
    <span class="header">Boostad adminportal</span> <br />
    <span class="name">{{ user_name }}</span>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import get from 'lodash/get';
export default {
  name: 'SubHeader',
  mixins: [mixin],
  data() {
    return {
      user_name: '',
    };
  },
  beforeMount() {},
  mounted() {
    this.user_name = get(
      this.$store.state.azurestore,
      'userInfo.user_name',
      ''
    );
  },
  methods: {},
};
</script>

<style scoped></style>
