import get from 'lodash/get';

export default {
  getVerifiedBidding: (state) => {
    return get(state, 'serviceOrder.verifiedBidding', false);
  },

  customerPrefix: (state) => {
    var pref = get(state, 'userInfo.customer_ext', null);
    if (pref == null) pref = get(state, 'serviceOrder.customer_ext', null);
    return pref;
  },

  jwtToken: (state) => {
    var jwt = get(state, 'userInfo.boostad_admin.Str', null);
    if (jwt == null) jwt = get(state, 'serviceOrder.boostad_admin.Str', null);
    return jwt;
  },

  surveyData: (state) => {
    return get(state, 'npsData.items', []);
  },

  surveyAnswers: (state) => {
    return get(state, 'npsData.answers', []);
  },

  surveyOffices: (state) => {
    var data = get(state, 'npsData.items', []);
    var offices = [];
    if (data.length > 0) {
      data.forEach((office) => {
        var obj = {
          customer_name: office.customer_name + ', ' + office.customerId,
          customerId: office.customerId,
        };
        //om kontoret inte redan finns i listan lägg till det
        if (!offices.some((item) => item.customerId === obj.customerId))
          offices.push(obj);
      });
      //sortera listan
      offices.sort((a, b) =>
        a.customer_name > b.customer_name
          ? 1
          : b.customer_name > a.customer_name
          ? -1
          : 0
      );

      // lägg till "Alla kontor" om det är fler än ett
      if (offices && offices.length > 1)
        offices.unshift({ customer_name: 'Alla kontor', customerId: '000000' });
    }

    return offices;
  },

  surveyBrokers: (state) => {
    var data = get(state, 'npsData.items', []);
    var brokers = [];
    if (data.length > 0) {
      data.forEach((broker) => {
        var obj = {
          broker_name: broker.broker_name,
          brokerId: broker.brokerId,
          customerId: broker.customerId,
        };
        //om kontoret inte redan finns i listan lägg till det
        if (!brokers.some((item) => item.brokerId === obj.brokerId))
          brokers.push(obj);
      });

      //sortera listan

      // lägg till "Alla mäklare" om det är fler än ett
      if (brokers && brokers.length > 1)
        brokers.unshift({ broker_name: 'Alla mäklare', brokerId: '000000' });
    }
    return brokers;
  },

  surveyEstateTypes: (state) => {
    var data = get(state, 'npsData.items', []);
    var types = [];
    var translatedTypes = [];

    if (data.length > 0) {
      data.forEach((type) => {
        //om typen inte redan finns i listan lägg till den
        if (!types.some((item) => item === type.estate_subType))
          types.push(type.estate_subType);
      });

      types.forEach((type) => {
        if (type == 'HousingCooperative')
          translatedTypes.push({
            name: 'Bostadsrätt',
            estate_subType: 'HousingCooperative',
          });
        else if (type == 'House')
          translatedTypes.push({
            name: 'Villa',
            estate_subType: 'House',
          });
        else if (type == 'Cottage')
          translatedTypes.push({
            name: 'Fritidshus',
            estate_subType: 'Cottage',
          });
        else if (type == 'Plot')
          translatedTypes.push({
            name: 'Tomt',
            estate_subType: 'Plot',
          });
        else if (type == 'Project')
          translatedTypes.push({
            name: 'Projekt',
            estate_subType: 'Project',
          });
        else if (type == 'Farm')
          translatedTypes.push({
            name: 'Gård',
            estate_subType: 'Farm',
          });
        else if (type == 'CommercialProperty')
          translatedTypes.push({
            name: 'Kommersiellt',
            estate_subType: 'CommercialProperty',
          });
        else if (type == 'Condominium')
          translatedTypes.push({
            name: 'Ägarlägenhet',
            estate_subType: 'Condominium',
          });
        else if (type == 'Foreignproperty')
          translatedTypes.push({
            name: 'Utlandsobjekt',
            estate_subType: 'Foreignproperty',
          });
        else if (type == 'Premises')
          translatedTypes.push({
            name: 'Lokal',
            estate_subType: 'Premises',
          });
      });

      //sortera listan

      // lägg till "Alla objektstyper" om det är fler än ett
      if (translatedTypes && translatedTypes.length > 1)
        translatedTypes.unshift({
          name: 'Alla objektstyper',
          estate_subType: '000000',
        });
    }
    return translatedTypes;
  },

  org_key: (state) => {
    return get(state, 'userInfo.org_key', null);
  },

  source: (state) => {
    var source = get(state, 'userInfo.source', null);
    if (source == null) source = get(state, 'serviceOrder.source', null);
    return source;
  },
  org: (state) => {
    return get(state, 'userInfo.org', null).toUpperCase();
  },
};
