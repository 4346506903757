export default {
  demo_NPS() {
    var obj = {
      items: [
        {
          date_time: '2023-03-22 14:24:06',
          org: 'DM',
          customerId: 'M16547',
          officeId: 'FIR16547',
          customer_name: 'Demomäklaren Sandviken',
          customer_address: 'Storgatan 19',
          customer_city: 'Sandviken',
          estateId: 'OBJ16547_1983038570',
          estate_streetAddress: 'Krusbärsvägen 9C',
          estate_city: 'GÄVLE',
          estate_area: 'Sätra',
          estate_zipcode: '80637',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'HousingCooperative',
          estate_rooms: '5',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '496D4E316B',
          code: 'ImN1k',
          messageId: '206272bf-ebc0-4ba5-a831-fe195d88e86b',
          phone_number: '+46708150520',
          vue_message: 'DM_Seller_SignatureDate',
          date: '2023-03-16',
          nps_answer: 10,
          nps_value: 100,
        },
        {
          date_time: '2023-03-22 14:24:41',
          org: 'DM',
          customerId: 'M16547',
          officeId: 'FIR16547',
          customer_name: 'Demomäklaren Sandviken',
          customer_address: 'Storgatan 19',
          customer_city: 'Sandviken',
          estateId: 'OBJ16547_1983038570',
          estate_streetAddress: 'Krusbärsvägen 9C',
          estate_city: 'GÄVLE',
          estate_area: 'Sätra',
          estate_zipcode: '80637',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'HousingCooperative',
          estate_rooms: '5',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '4B5034534E',
          code: 'KP4SN',
          messageId: '13f007b4-c118-41b0-b55c-e976bb6f64ff',
          phone_number: '+46700848815',
          vue_message: 'DM_Buyer_SignatureDate',
          date: '2023-03-16',
          nps_answer: 10,
          nps_value: 100,
        },
        {
          date_time: '2023-03-24 10:16:59',
          org: 'DM',
          customerId: 'M13727',
          officeId: 'FIR13727',
          customer_name: 'Demomäklaren mäkleri AB',
          customer_address: 'Storgatan 19',
          customer_city: 'Sandviken',
          estateId: 'OBJ13727_1977843015',
          estate_streetAddress: 'Hackstensvägen 2',
          estate_city: 'Gävle',
          estate_area: 'Hemlingby',
          estate_zipcode: '80257',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'House',
          estate_rooms: '5',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '77447A6555',
          code: 'wDzeU',
          messageId: '3f8ceec1-bb2f-4f14-89f3-2fda47ce60d1',
          phone_number: '+46706168262',
          vue_message: 'DM_Participant_ViewingDate',
          date: '2023-03-21',
          nps_answer: 8,
          nps_value: 0,
        },
        {
          date_time: '2023-03-25 10:16:41',
          org: 'DM',
          customerId: 'M13727',
          officeId: 'FIR13727',
          customer_name: 'Demomäklaren mäkleri AB',
          customer_address: 'Storgatan 19',
          customer_city: 'Sandviken',
          estateId: 'OBJ13727_1977843015',
          estate_streetAddress: 'Hackstensvägen 2',
          estate_city: 'Gävle',
          estate_area: 'Hemlingby',
          estate_zipcode: '80257',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'House',
          estate_rooms: '5',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '5169764D7A',
          code: 'QivMz',
          messageId: 'f94cf160-db10-4663-8d76-978f503e4c94',
          phone_number: '+46730569403',
          vue_message: 'DM_Seller_SignatureDate',
          date: '2023-03-24',
          nps_answer: 10,
          nps_value: 100,
        },
        {
          date_time: '2023-03-25 10:18:13',
          org: 'DM',
          customerId: 'M23884',
          officeId: 'FIR23884',
          customer_name: 'Demomäklaren AB',
          customer_address: 'Drottninggatan 18',
          customer_city: 'Gävle',
          estateId: 'OBJ23884_1987166990',
          estate_streetAddress: 'Ängshyddevägen 5',
          estate_city: 'Furuvik',
          estate_area: 'Furuvik',
          estate_zipcode: '81491',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'House',
          estate_rooms: '6',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '4651474448',
          code: 'FQGDH',
          messageId: 'e9598548-45b6-4fb7-91c7-59be2b959268',
          phone_number: '+46722520458',
          vue_message: 'DM_Participant_ViewingDate',
          date: '2023-03-22',
          nps_answer: 10,
          nps_value: 100,
        },
        {
          date_time: '2023-03-31 09:17:16',
          org: 'DM',
          customerId: 'M23884',
          officeId: 'FIR23884',
          customer_name: 'Demomäklaren AB',
          customer_address: 'Drottninggatan 18',
          customer_city: 'Gävle',
          estateId: 'OBJ23884_1987166990',
          estate_streetAddress: 'Ängshyddevägen 5',
          estate_city: 'Furuvik',
          estate_area: 'Furuvik',
          estate_zipcode: '81491',
          estate_county: 'Gävleborgs',
          estate_municipality: 'Gävle',
          estate_subType: 'House',
          estate_rooms: '6',
          brokerId: 'HAN07D05C09BBE845968DE79F600E7E060E',
          broker_name: 'Lisa Öhrvall',
          broker_email: 'lisa.ohrvall@demomaklaren.se',
          broker_phone: '070-253 26 00',
          hex: '6254544248',
          code: 'bTTBH',
          messageId: '7e4d9080-52a9-4eef-9c1e-dce5daa0ac6e',
          phone_number: '+46704815256',
          vue_message: 'DM_Seller_SignatureDate',
          date: '2023-03-30',
          nps_answer: 10,
          nps_value: 100,
        },
      ],
      answers: [
        {
          hex: '4651474448',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Bemötandet',
          json: '5',
        },
        {
          hex: '4651474448',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Engagemanget',
          json: '5',
        },
        {
          hex: '4651474448',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Engagemanget',
          json: '5',
        },
        {
          hex: '4651474448',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Kuskapen om bostaden',
          json: '5',
        },
        {
          hex: '4651474448',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '10',
        },
        {
          hex: '4651474448',
          name: 'Sp_info',
          type: 'Söderberg & Partner',
          info: 'Fick du information',
          json: '"NEJ"',
        },
        {
          hex: '4651474448',
          name: 'Sp_interest',
          type: 'Söderberg & Partner',
          info: 'Vill du få kontakt',
          json: '"NEJ"',
        },
        {
          hex: '4651474448',
          name: 'Good_experience',
          type: 'Upplevelse',
          info: 'Vad tycker du var särskilt bra',
          json: '["Bemötandet och engagemanget","Bostadens känsla/skick"]',
        },
        {
          hex: '4651474448',
          name: 'Led_by',
          type: 'Visningen leddes av',
          info: '',
          json: '"Ansvarig fastighetsmäklare"',
        },
        {
          hex: '496D4E316B',
          name: 'Reason_to_hire',
          type: 'Anledning',
          info: 'Främsta skälet till att du anlitade Erik Olsson',
          json: '"Bra marknadsmaterial"',
        },
        {
          hex: '496D4E316B',
          name: 'Good_comment',
          type: 'Fritext',
          info: 'bra',
          json: '"Ett bra o mycket uppskattat bemötandet d snabb affär"',
        },
        {
          hex: '496D4E316B',
          name: 'General_comment',
          type: 'Fritext',
          info: 'generell',
          json: '"Kommer att rekommendera er till andra som ska sälja i framtiden o även oss"',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur förklarade fastighetsmäklaren de olika köpekontraktshandlingarna inför/vid kontraktsskrivningen?',
          json: '5',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur förklarade fastighetsmäklaren de olika köpekontraktshandlingarna inför/vid kontraktsskrivningen?',
          json: '3',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du att fastighetsmäklaren presenterade mäklartjänsten och marknadsföringen av din bostad?',
          json: '5',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du bostaden presenterades av fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du första kontakten med fastighetsmäklaren?',
          json: '4',
        },
        {
          hex: '496D4E316B',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du informationen från fastighetsmäklaren inför och efter visningen av din bostad?',
          json: '5',
        },
        {
          hex: '496D4E316B',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '10',
        },
        {
          hex: '496D4E316B',
          name: 'Reference',
          type: 'Vill vara referens',
          info: '',
          json: '"JA"',
        },
        {
          hex: '4B5034534E',
          name: 'Bid_info',
          type: 'Budgivning',
          info: 'Fick du löpande information vid budgivning?',
          json: '"Ingen budgivning"',
        },
        {
          hex: '4B5034534E',
          name: 'Good_comment',
          type: 'Fritext',
          info: 'bra',
          json: '"Mäklarens bemötande, kändes som vi var i trygga händer."',
        },
        {
          hex: '4B5034534E',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Engagerad',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Noggrann',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Påläst',
          json: '4',
        },
        {
          hex: '4B5034534E',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Serviceinriktad',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '10',
        },
        {
          hex: '4B5034534E',
          name: 'Treatment',
          type: 'Upplevelse',
          info: 'Hur förklarade fastighetsmäklaren de olika köpekontraktshandlingarna inför/vid kontraktsskrivningen?',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Treatment',
          type: 'Upplevelse',
          info: 'Hur tyckte du bostaden presenterades av fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Treatment',
          type: 'Upplevelse',
          info: 'Hur upplevde du kontakten med fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Treatment',
          type: 'Upplevelse',
          info: 'Hur upplevde du visningen av bostaden?',
          json: '5',
        },
        {
          hex: '4B5034534E',
          name: 'Reference',
          type: 'Vill vara referens',
          info: '',
          json: '"JA"',
        },
        {
          hex: '5169764D7A',
          name: 'Reason_to_hire',
          type: 'Anledning',
          info: 'Främsta skälet till att du anlitade Erik Olsson',
          json: '"Rekommendation"',
        },
        {
          hex: '5169764D7A',
          name: 'Good_comment',
          type: 'Fritext',
          info: 'bra',
          json: '"Mäklaren höll nära kontakt hela tiden och var förstående i vår situation. Vi litade hela vägen på honom och det ångrar jag inte en sekund. "',
        },
        {
          hex: '5169764D7A',
          name: 'General_comment',
          type: 'Fritext',
          info: 'generell',
          json: '"Tusen tack Lisa för all hjälp och stöttning. Jag rekommenderar er varmt. "',
        },
        {
          hex: '5169764D7A',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur förklarade fastighetsmäklaren de olika köpekontraktshandlingarna inför/vid kontraktsskrivningen?',
          json: '5',
        },
        {
          hex: '5169764D7A',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du att fastighetsmäklaren presenterade mäklartjänsten och marknadsföringen av din bostad?',
          json: '5',
        },
        {
          hex: '5169764D7A',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du bostaden presenterades av fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '5169764D7A',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du första kontakten med fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '5169764D7A',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du informationen från fastighetsmäklaren inför och efter visningen av din bostad?',
          json: '5',
        },
        {
          hex: '5169764D7A',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '10',
        },
        {
          hex: '5169764D7A',
          name: 'Reference',
          type: 'Vill vara referens',
          info: '',
          json: '"JA"',
        },
        {
          hex: '6254544248',
          name: 'Reason_to_hire',
          type: 'Anledning',
          info: 'Främsta skälet till att du anlitade Erik Olsson',
          json: '"Övrigt"',
        },
        {
          hex: '6254544248',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur förklarade fastighetsmäklaren de olika köpekontraktshandlingarna inför/vid kontraktsskrivningen?',
          json: '5',
        },
        {
          hex: '6254544248',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du att fastighetsmäklaren presenterade mäklartjänsten och marknadsföringen av din bostad?',
          json: '5',
        },
        {
          hex: '6254544248',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur tyckte du bostaden presenterades av fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '6254544248',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du första kontakten med fastighetsmäklaren?',
          json: '5',
        },
        {
          hex: '6254544248',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Hur upplevde du informationen från fastighetsmäklaren inför och efter visningen av din bostad?',
          json: '5',
        },
        {
          hex: '6254544248',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '10',
        },
        {
          hex: '6254544248',
          name: 'Reference',
          type: 'Vill vara referens',
          info: '',
          json: '"JA"',
        },
        {
          hex: '77447A6555',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Bemötandet',
          json: '4',
        },
        {
          hex: '77447A6555',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Engagemanget',
          json: '4',
        },
        {
          hex: '77447A6555',
          name: 'Broker',
          type: 'Mäklarens betyg',
          info: 'Kuskapen om bostaden',
          json: '4',
        },
        {
          hex: '77447A6555',
          name: 'Nps',
          type: 'Nps värde',
          info: '',
          json: '8',
        },
        {
          hex: '77447A6555',
          name: 'Sp_info',
          type: 'Söderberg & Partner',
          info: 'Fick du information',
          json: '"NEJ"',
        },
        {
          hex: '77447A6555',
          name: 'Sp_interest',
          type: 'Söderberg & Partner',
          info: 'Vill du få kontakt',
          json: '"NEJ"',
        },
        {
          hex: '77447A6555',
          name: 'Good_experience',
          type: 'Upplevelse',
          info: 'Vad tycker du var särskilt bra',
          json: '["Bemötandet och engagemanget","Dagen och tiden då bostaden visades"]',
        },
        {
          hex: '77447A6555',
          name: 'Led_by',
          type: 'Visningen leddes av',
          info: '',
          json: '"Ansvarig fastighetsmäklare"',
        },
      ],
    };

    return obj;
  },
  demo_BestComments() {
    var obj = {
      status: 1,
      org: 'IP',
      customerId: 'M21889',
      brokerId: 'HAN3D3C93E6F3554EE881BD1FD94C622A35',
      brokerName: 'Rickard Smith',
      brokerImgUrl:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/demo_broker_rickard.jpg',
      brokerTitle: 'Reg. fastighetsmäklare / Franchisetagare',
      brokerPhoneNumber: '+46702251743',
      items: [
        {
          AnswerDate: '2023-02-10T10:13:37',
          EstateId: 'OBJ32188_1957663851',
          SubType: 'HousingCooperative',
          StreetAddress: 'Villagatan 7',
          ContactId: 'ADR6C853F2345EA485CADC705CD9111CF12',
          ContactRole: 'Buyer',
          ContactName: 'Lisa Marklund',
          Rating: 10,
          Nps: 100,
          Question: 'Hur upplevde du mäklarens engagemang?',
          Answer:
            'Som förstagångsköpare så upplevde jag att mäklaren förstod mitt behov. Jag är supernöjd.',
        },
        {
          AnswerDate: '2023-02-10T11:38:26',
          EstateId: 'OBJ32188_1957663851',
          SubType: 'HousingCooperative',
          StreetAddress: 'Villagatan 7',
          ContactId: 'ADRDCB9BA8B0F574BE6ACEAF107BA6CC42B',
          ContactRole: 'Seller',
          ContactName: 'Pekka Salo',
          Rating: 10,
          Nps: 100,
          Question:
            'Hur sannolikt är det att du skulle rekommendera din mäklare på Demomäklaren till en vän eller kollega?',
          Answer: 'Alla dagar i veckan att jag skulle göra det!',
        },
        {
          AnswerDate: '2023-06-01T19:58:58',
          EstateId: 'OBJ32188_1994252425',
          SubType: 'House',
          StreetAddress: 'Gamla Sturevägen 5',
          ContactId: 'ADR262AF361B8054015963F6845EA63A248',
          ContactRole: 'Seller',
          ContactName: 'Anna Svensson',
          Rating: 10,
          Nps: 100,
          Question: 'Hur upplevde du mäklarens engagemang?',
          Answer:
            'Rickard var superengagerad och ställde upp i allt kring försäljningen! ',
        },
        {
          AnswerDate: '2022-12-01T10:16:05',
          EstateId: 'OBJ32188_1961809684',
          SubType: 'HousingCooperative',
          StreetAddress: 'Landsstigen 6',
          ContactId: 'ADR0FF624385EEE41959798D3A1B9DB7748',
          ContactRole: 'Seller',
          ContactName: 'Abu Samar',
          Rating: 10,
          Nps: 100,
          Question: 'Hur upplevde du mäklarens engagemang?',
          Answer:
            'Perfekt då han gick igenom för oss båda hur han tänker kring försäljningen men lyssnade på vad vi ville gå ut med denna försäljningen och bemötte det bra.',
        },
        {
          AnswerDate: '2022-11-28T11:12:34',
          EstateId: 'OBJ32188_1926019916',
          SubType: 'HousingCooperative',
          StreetAddress: 'Solgläntan 6',
          ContactId: 'ADR92996BA428884EE99D92CB7C0ED272F6',
          ContactRole: 'Seller',
          ContactName: 'Sandra Lantz',
          Rating: 10,
          Nps: 100,
          Question: 'Hur upplevde du mäklarens engagemang?',
          Answer:
            'Rickard var alltid lika engagerad, i vilka frågor han än utsattes för. Han var alltid tillgänglig och ställde alltid upp på samtal när spörsmål uppstod. Jag sålde min lägenhet under den oroligaste perioden 2022 och Rickard lugnade mig alltid efter sömnlösa nätter. Rickard hade också förmågan att hantera stressen från mig som säljare och bemötte mig alltid respektfullt och trevligt även om tonen inte alltid var så trevlig från min sida. Tack!',
        },
      ],
    };

    return obj;
  },
};
