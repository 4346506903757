<template>
  <div style="height: 100%">
    <div>
      <v-label v-if="tempUser">INLOGGAD SOM: {{ tempUser }}</v-label>
      <topHeader />
      <subHeader />
      <navBar />
    </div>
    <div class="main-container">
      <div class="content-container">
        <br />

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import mixin from '@/mixin.js';
import topHeader from '@/components/header.vue';
import subHeader from '@/components/subHeader.vue';
import navBar from '@/components/navBar.vue';
//import Description from '@/components/description.vue';

export default {
  name: 'HomeView',
  mixins: [mixin],
  data() {
    return {
      loadingPage: true,
      delaySpinner: false,
    };
  },
  watch: {},
  components: {
    topHeader,
    subHeader,
    navBar,
  },
  beforeMount() {},
  mounted() {},

  computed: {
    tempUser() {
      return get(this.$store.state.infopingstore, 'temp_user', null);
    },
  },
  methods: {},
};
</script>
<style scoped></style>
