<template>
  <div>
    <v-container>
      <v-row>
        <v-label>GENERERA RAPPORTER</v-label>

        <v-container>
          <!-- class="borderContainer" min-width: 800px; -->
          <v-card class="noSwim" style="min-width: 800px; padding: 20px">
            <!--  <v-toolbar flat color="white" dense>  :min-width="$vuetify.breakpoint.smAndUp ? 800 : 'auto'"         style="padding: 20px"
              <v-toolbar-title>Välj rapport</v-toolbar-title>
            </v-toolbar>
            <v-tabs v-model="taboo" vertical>
              <v-tab v-for="i in reports" :key="i.id">
                {{ i.name }}
              </v-tab>

              <v-tabs-items v-model="taboo">
                <v-tab-item v-for="i in reports" :key="i.id">
                  <v-card flat>
                    <v-card-text>
                      <p v-if="i.id == 'report_office'">
                        Svar från kundundersökningar. Rapporter mailas
                        regelbundet, här kan du välja att generera rapporter med
                        andra inställningar och tidsintervaller.
                      </p>
                      <p v-if="i.id == 'report_viewing_statistics'">
                        en annan text om visningsrapporten
                      </p>-->
            <v-row>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-select
                    v-if="this.support == '1'"
                    dense
                    outlined
                    :class="[darkMode ? 'inputDark' : 'inputLight']"
                    :items="orgs"
                    item-text="name"
                    item-value="org_key"
                    label="Välj kund:"
                    v-model="selectedCustomer"
                  ></v-select>
                </v-row>
                <v-row no-gutters>
                  <!-- Rapport-->
                  <v-select
                    dense
                    outlined
                    :class="[darkMode ? 'inputDark' : 'inputLight']"
                    item-text="name"
                    item-value="id"
                    return-object
                    :items="reports"
                    label="Välj rapport:"
                    v-model="selectedReport"
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-row no-gutters>
                  <!-- Kontor customerid        -->
                  <v-select
                    v-if="vIfSelectOffice()"
                    dense
                    outlined
                    :class="[darkMode ? 'inputDark' : 'inputLight']"
                    :items="offices"
                    item-text="name"
                    item-value="customerId"
                    return-object
                    label="Välj kontor:"
                    v-model="selectedOffice"
                  ></v-select>
                </v-row>
                <v-row no-gutters>
                  <!-- from datum-->
                  <span
                    v-if="
                      this.selectedReport &&
                      this.selectedReport.id &&
                      this.selectedReport.id == 'report_viewing_statistics'
                    "
                  >
                    <v-label
                      >Välj måndagen i den vecka du vill generera rapport för
                    </v-label>
                    <br />
                    <br />
                  </span>
                  <span
                    v-if="
                      this.selectedReport &&
                      this.selectedReport.id &&
                      this.selectedReport.id == 'report_access_survey_comments'
                    "
                  >
                    <v-label
                      >Välj det datum du vill se kommentarer för
                    </v-label>
                    <br />
                    <br />
                  </span>

                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        outlined
                        background-color="#f6f6f6"
                        v-model="fromDate"
                        label="Fr.o.m"
                        append-icon="mdi-calendar"
                        @click:append="menu = true"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                      locale="sv-se"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
                <v-row no-gutters>
                  <!-- försättssida  -->
                  <v-select
                    v-if="vIfCompConfig()"
                    dense
                    outlined
                    background-color="#f6f6f6"
                    item-text="text"
                    item-value="value"
                    :items="reportDetails"
                    label="Försättssida:"
                    v-model="selectedDetails"
                  ></v-select>
                </v-row>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-row no-gutters>
                  <!-- mäklare    -->
                  <v-select
                    v-if="vIfSelectBroker()"
                    dense
                    outlined
                    :class="[darkMode ? 'inputDark' : 'inputLight']"
                    :items="brokers"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Välj mäklare:"
                    v-model="selectedBroker"
                  ></v-select>
                </v-row>
                <v-row no-gutters>
                  <!-- till datum-->
                  <v-menu
                    v-if="vIfToDate()"
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        dense
                        outlined
                        :class="[darkMode ? 'inputDark' : 'inputLight']"
                        v-model="toDate"
                        label="T.o.m."
                        append-icon="mdi-calendar"
                        @click:append="menu2 = true"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      no-title
                      scrollable
                      :first-day-of-week="1"
                      locale="sv-se"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-row>
                <v-row no-gutters>
                  <!-- Kontor detaljerade svar    -->
                  <v-select
                    v-if="vIfCompConfig()"
                    outlined
                    dense
                    outlined
                    :class="[darkMode ? 'inputDark' : 'inputLight']"
                    item-text="text"
                    item-value="value"
                    :items="reportAnswers"
                    label="Visa detaljerade svar:"
                    v-model="selectedAnswers"
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-btn block color="primary" @click="getReport()">
                Skapa rapport</v-btn
              >
            </v-row>
            <!--      </v-card-text>
                  </v-card>
           </v-tab-item>
              </v-tabs-items>
            </v-tabs>-->
          </v-card>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import get from 'lodash/get';
import mixin from '@/mixin.js';
export default {
  name: 'Reports',
  mixins: [mixin],
  data() {
    return {
      selectedOffice: '',
      selectedReport: '',
      offices: [],
      //orgs: [],
      //  reports: [],
      // customisedReport: false,
      reports: [],
      toDate: '',
      fromDate: '',
      menu: false,
      menu2: false,
      selectedCustomer: '',
      //taboo: null,
      data: null,
      //  selectedBroker: { name: 'Alla mäklare', id: '0' },
      selectedBroker: '0',
      reportAnswers: [
        { text: 'Visa inga svar', value: '0' },
        { text: 'Visa endast svar med kommentarer', value: '1' },
        { text: 'Visa alla svar', value: '2' },
      ],
      reportDetails: [
        { text: 'Sammanställning', value: '1' },
        { text: 'Ingen sammanställning, endast svaren', value: '0' },
      ],
      selectedDetails: '',
      selectedAnswers: '',
      // support: '0',

      session: get(this.$store.state.infopingstore, 'session', null),
      org_key: '',
    };
  },
  watch: {
    selectedOffice(val) {
      console.log('nu tömmer vi brokers', val);
      if (val && val != null) {
        this.$store.dispatch('infopingstore/clear_brokers');

        this.selectedBroker = '';

        this.fetchBrokers();
      }
    },
    selectedCustomer() {
      this.$store.dispatch('infopingstore/clear_brokers');
      this.org_key = this.selectedCustomer;
      this.clearSettings();

      // this.selectedReport = null;

      this.findReports();
    },

    selectedReport(val) {
      console.log('nu väljs selectedReport', this.selectedReport);

      if (val && val != null) {
        this.offices = [];
        //  this.clearSettings();

        this.fetchBrokerOffices(val.id);
        // välj ett kontor om det bara finns ett

        if (
          this.vIfSelectOffice() == true &&
          this.offices &&
          this.offices.length == '1'
        ) {
          this.selectedOffice = this.offices[0];
        }
      }
      this.customisedReport();
    },

    brokers() {
      if (
        this.vIfSelectBroker() == true &&
        this.brokers &&
        this.brokers.length == '1'
      ) {
        this.selectedBroker = this.brokers[0];
      }
    },
  },
  computed: {
    //   ...mapGetters({ orgs: 'infopingstore/report_orgs',  }), //  { customers: 'infopingstore/customers' },
    orgs() {
      var orgs = this.$store.getters['infopingstore/access_orgs']('report');

      return orgs;
    },

    support() {
      if (
        get(this.$store.state.infopingstore, 'user_type', null) == 'superagent'
      )
        return '1';
      else return '0';
    },

    brokers() {
      var arr = new Array();
      var report_brokers = get(this.$store.state.infopingstore, 'brokers', []);

      if (report_brokers.length > 0) {
        report_brokers.forEach((element) => {
          if (
            element.customers[0].items &&
            element.customers[0].items.length > 0
          ) {
            element.customers[0].items.forEach((broker) => {
              var line = {
                name: broker.name,
                id: broker.value,
              };
              if (!arr.includes(line)) arr.push(line);
            });
          }
        });
        // sortera array med objekt
        arr.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }

      arr.unshift({ name: 'Alla mäklare', id: '0' });

      //går inte att kolla length om den inte finns så vi tar bort "alla mäklare" igen om de bara är en mäklare
      if (arr && arr.length == 2 && arr[0].id == '0') arr.shift();

      return arr;
    },
  },

  beforeMount() {},
  mounted() {
    if (this.support == '0') {
      this.org_key = get(
        this.$store.state.azurestore,
        'userInfo.org_key',
        null
      );
      this.findReports();
    }
  },
  created() {},

  methods: {
    customisedReport() {
      var to = this.vIfToDate();
      this.fromDate = this.getDate();
      console.log('from date i customised', this.fromDate);

      if (to == true) this.toDate = new Date().toISOString().substr(0, 10);

      var details = this.vIfCompConfig();
      if (details == true) {
        this.selectedDetails = '1';
        this.selectedAnswers = '1';
      }
      //om kontor och  bara ett kontor välj det
      if (
        this.vIfSelectOffice() == true &&
        this.offices &&
        this.offices.length == '1'
      ) {
        this.selectedOffice = this.offices[0];
      }

      if (
        this.vIfSelectBroker() == true &&
        this.brokers &&
        this.brokers.length == '1'
      ) {
        this.selectedBroker = this.brokers[0];
      }
    },
    findReports() {
      var access = get(this.$store.state.infopingstore, 'access', []);

      //hitta alla rapporter i access
      var allReports = access.filter(
        (r) =>
          r.name.includes('report') &&
          // filtrera fram rapporter för vald org_key
          r.orgs.some((o) => o.org_key == this.org_key)
      );

      // översätt namnet men behålla originalnamnet som id
      var myReports = allReports.map((r) => {
        return {
          id: r.name,
          name: this.translateReportName(r.name),
        };
      });

      myReports.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.reports = myReports;

      // myReports = myReports.sort(); funkar inte
      // return myReports;

      //om endast en rapport välj den
      if (this.reports && this.reports.length == '1')
        this.selectedReport = this.reports[0];
    },

    fetchBrokerOffices(report) {
      this.offices = this.$store.getters['infopingstore/broker_offices'](
        report,
        this.org_key
      );

      if (
        this.selectedReport.id != 'report_viewing_statistics' &&
        this.offices &&
        this.offices.length > 1
      ) {
        this.offices.unshift({ name: 'Alla kontor', customerId: '0' });
        this.selectedOffice = this.offices[0];
      }
    },

    fetchBrokers() {
      var obj = {};
      if (this.org_key == null || this.org_key == '')
        this.org_key = this.selectedCustomer;

      if (this.selectedOffice.customerId == '0') {
        this.$store.dispatch('infopingstore/clear_brokers');
      } else {
        obj.customerId = this.selectedOffice.customerId;
        obj.org_key = this.org_key;
        obj.name = this.selectedReport.id;

        this.$store.dispatch('infopingstore/fetch_brokers', obj);
      }
    },

    getReport() {
      if (!this.selectedReport) {
        this.alertDialogNoCancel(
          'Ingen rapport',
          'Du måste välja vilken rapport du vill ha',

          () => console.log('user ok')
        );
      } else if (
        this.selectedReport.id == 'report_viewing_statistics' &&
        (this.selectedOffice.customerId == '0' || !this.selectedOffice)
      ) {
        this.alertDialogNoCancel(
          'Välj kontor',
          'Du måste välja vilket kontor rapporten ska genereras för',

          () => console.log('user ok')
        );
      } else if (
        this.selectedReport.id == 'report_access_survey_compilation_broker' &&
        this.selectedBroker.id == '0'
      ) {
        this.alertDialogNoCancel(
          'Välj mäklare',
          'Du måste välja vilken mäklare rapporten ska genereras för',

          () => console.log('user ok')
        );
      } else {
        var reportObject = {};

        reportObject.session = this.session;
        reportObject.org_key = this.org_key;
        reportObject.name = this.selectedReport.id;
        // if (
        //   this.customisedReport == false &&
        //   this.selectedReport.id != 'report_viewing_statistics'
        // ) {
        //   reportObject.report_name = 'standard'; //gäller ej visningsrapporten
        // }

        //widerlöv kan inte dra ut rapporter för enskilda kontor

        if (
          this.selectedOffice &&
          this.selectedOffice.customerId != '0' &&
          this.selectedBroker.id == '0' &&
          this.org_key == '2099' &&
          this.selectedReport.id != 'report_viewing_statistics'
        ) {
          this.alertDialogNoCancel(
            'Ogiltig rapport',
            'Du kan inte välja ett kontor om du inte vill ha en rapport för en specifik mäklare. Välj mäklare eller välj "Alla kontor"',

            () => console.log('user ok')
          );
        } else {
          if (this.selectedOffice && this.selectedOffice.customerId != '0')
            reportObject.customerId = this.selectedOffice.customerId;

          if (
            this.selectedBroker &&
            this.org_key == '2099' &&
            this.selectedBroker.id != '0'
          )
            reportObject.broker_name = this.selectedBroker.name;
          else if (this.selectedBroker && this.selectedBroker.id != '0')
            reportObject.brokerId = this.selectedBroker.id;

          if (this.fromDate) {
            reportObject.date_from = this.fromDate;
          }

          if (this.toDate) reportObject.date_to = this.toDate;
          if (this.selectedDetails)
            reportObject.cover_page = this.selectedDetails;
          if (this.selectedAnswers && this.selectedAnswers == '1')
            reportObject.only_comments = '1';
          else if (this.selectedAnswers && this.selectedAnswers == '2')
            reportObject.all_answers = '1';

          if (this.selectedReport.id == 'report_intake')
            reportObject.content_type = 'xls';

          var settings = JSON.stringify(reportObject);
          // console.log('report settings', settings);

          var link = 'https://infoping.net/REST_FILE/ask/Q/Rapport/?sok=';
          const strictUriEncode = require('strict-uri-encode');
          var encodedlink = strictUriEncode(settings);
          window.open(link + encodedlink, '_blank');
        }
      }
    },

    translateReportName(report) {
      switch (report) {
        case 'report_viewing_statistics':
          return 'Visningsrapport';

        case 'report_viewing_answers':
          return 'Visningsdeltagarnas svar';

        case 'report_office': // smh
          return 'Kundenkät';
        case 'report_access_survey_comments':
          return 'Kundenkät - gårdagens kommentarer';
        case 'report_access_survey_compilation': //widerlöv
          return 'Kundenkät - sammanställning';
        case 'report_access_survey_compilation_broker':
          return 'Kundenkät - mäklare';
        case 'report_intake':
          return 'Intag';
        default:
          return report;
      }
    },

    // vIfFromDate() {
    //   if (this.customisedReport == true) return true;
    //   else return false;
    // },
    vIfToDate() {
      if (
        this.selectedReport.id == 'report_access_survey_comments' ||
        this.selectedReport.id == 'report_viewing_statistics'
      )
        return false;
      else return true;
    },

    vIfSelectOffice() {
      if (this.selectedReport.id == 'report_viewing_statistics') return true;
      if (this.selectedReport.id == 'report_intake') return false;
      if (this.selectedReport.id == 'report_access_survey_compilation_broker')
        return true;

      if (
        this.selectedReport.id == 'report_office' ||
        this.selectedReport.id == 'report_access_survey_compilation' ||
        this.selectedReport.id == 'report_access_survey_compilation_broker'
      )
        return true;
      else return false;
    },

    vIfSelectBroker() {
      if (
        this.selectedReport &&
        this.selectedReport.id == 'report_access_survey_compilation_broker'
      )
        return true;

      if (
        this.selectedReport.id == 'report_office' ||
        this.selectedReport.id == 'report_access_survey_compilation' ||
        this.selectedReport.id == 'report_access_survey_compilation_broker'
      )
        return true;
      else return false;
    },

    vIfCompConfig() {
      if (
        this.selectedReport.id == 'report_office' ||
        this.selectedReport.id == 'report_access_survey_compilation' ||
        this.selectedReport.id == 'report_access_survey_compilation_broker'
      )
        return true;
      else return false;
    },

    getDate() {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      d = d.toISOString().substr(0, 10);

      return d;
    },

    clearSettings() {
      this.selectedOffice = '';
      this.selectedBroker = '';
      this.selectedDetails = '';
      this.selectedAnswers = '';
      this.fromDate = '';
      this.toDate = '';
    },

    is_support() {
      //kolla om superagent annars sätt org_key

      if (
        get(this.$store.state.infopingstore, 'user_type', null) == 'superagent'
      ) {
        this.support = '1';
      } else {
        this.support = '0';

        this.org_key = get(
          this.$store.state.azurestore,
          'userInfo.org_key',
          null
        );
      }
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },
  },
};
</script>
<style scoped>
/* anpassa höjden så att hela texten syns i v-select funkar inte globalt 
::v-deep usage as a combinator has been deprecated. Use :deep(<inner-selector>) instead.
.overflowOverride ::v-deep .v-select__selection {
  overflow: visible !important;
}*/
.compact-select {
  padding-top: 10px;
  transform: scale(0.8);
  transform-origin: right;
  max-width: 200 px;
}

.inputDark {
  background-color: #3a3a3a;
  border-color: #3a3a3a;
}
.inputLight {
  background-color: #f6f6f6;
}

.noSwim {
  overflow-x: hidden !important;
}
</style>
