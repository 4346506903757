<template>
  <v-app>
    <div id="app">
      <span id="thecenter">
        <Spinner v-if="loadingPage" :color="spinnerColor"
      /></span>
      <!--borde komma felmeddelande om det inte finns customerprefix (eller login)-->
      <router-view v-if="customerPrefix || showLogin"></router-view>
      <!--  simple alert popup, activated from Store  " -->
      <Alert />
      <BrokerLogin v-if="brokerLogin" />
    </div>
  </v-app>
</template>
<script>
//import Login from './views/Login.vue';
import rest2Api from '@/api/rest2Api.js';
import mixin from '@/mixin.js';
import Alert from '@/components/dialogs/alert.vue';
import {
  COOKIE_NAME_SESSION,
  // COOKIE_NAME_CUSTOMERPREFIX,
} from '@/constants.js';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import BrokerLogin from '@/components/dialogs/brokerLogin.vue';
import Spinner from '@/components/spinners/dottsOnRow.vue';

//import get from 'lodash/get';
export default {
  name: 'App',
  mixins: [mixin],
  data() {
    return {
      urlHasSeesion: false,
      showLogin: false,
      lang: 'sv',
      brokerLogin: false,
      setCookie: true,
      spinnerColor: '#ffffff',
    };
  },
  components: {
    Alert,
    BrokerLogin,
    Spinner,
  },
  async beforeMount() {
    this.lang = (navigator.language || navigator.userLanguage).substr(0, 2);
    // this.fetchLang();

    //dirigera om användaren till https om de kommer via http  (endast prod)
    var loc = window.location + '';
    if (loc.indexOf('http://') == 0 && process.env.NODE_ENV === 'production') {
      window.location.href = loc.replace('http://', 'https://');
      return;
    }

    // STORE GUID
    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    await this.fetch_source_id(guid);
    await this.fetch_source_name('AdminPortal');

    var session = '';

    // get customerbranding from cookie (if it exists)
    // if (this.$cookies.isKey(COOKIE_NAME_CUSTOMERPREFIX)) {
    //   var customerBranding = this.$cookies.get(COOKIE_NAME_CUSTOMERPREFIX);
    //   document.getElementsByTagName('body')[0].classList.add(customerBranding);
    //   console.log(' cutomer brand', customerBranding);
    // }

    var url = window.location.href;
    //kolla om det finns token i url (om anv kommer från mäklarsystemet)
    if (url.includes('token')) {
      this.$store.dispatch('azurestore/fetch_quick_info', url);
      this.$store.dispatch('azurestore/fetch_broker_info', url).then((res) => {
        var serviceOrder = get(
          this.$store.state.azurestore,
          'serviceOrder',
          null
        );
        if (serviceOrder.status == 0) {
          if (
            serviceOrder.error_who == 'Vitec' ||
            serviceOrder.error_who == 'Mspecs'
          ) {
            this.alertDialogNoCancel(
              'Inget svar',
              'Mäklarsystemet svarar inte, prova att öppna sidan igen',
              () => this.logout()
            );
          } else {
            this.alertDialogNoCancel(
              'Inget svar',
              'Felkod: ' + serviceOrder.error,
              () => this.logout()
            );
          }
        } else {
          if (
            !res &&
            serviceOrder.error &&
            serviceOrder.error == 'phone_number missing'
          ) {
            this.alertDialogNoCancel(
              'Mobilnr saknas',
              'Du måste lägga in mobilnummer på din användare i mäklarsystemet',
              () => this.logout()
            );
          } else if (serviceOrder.hasExpired == true) {
            this.alertDialogNoCancel(
              'Utloggad',
              'Sessionen har gått ut. Gå in igen via objektet i mäklarsystemet.',
              () => this.logout()
            );
          } else if (!res && serviceOrder.status == 1) {
            this.alertDialogNoCancel(
              'Fel',
              'Inloggningen misslyckades. Kontakta Boostad support om felet kvarstår, 018-681622, support@boostad.net',
              () => this.logout()
            );
            return;
          } else if (res == 'Ingen session') {
            this.alertDialogNoCancel(
              'Fel',
              'Inloggningen misslyckades. Kontakta Boostad support om felet kvarstår, 018-681622, support@boostad.net (Felmeddelande: Ingen session)',
              () => this.logout()
            );
            return;
          }
          session = res;

          this.getUserInfo(session);

          this.$store.dispatch('azurestore/getEstate').then((res) => {
            if (res && res.status == 0) {
              this.alertDialogNoCancel(
                '',
                'Kan inte hämta bostaden: ' + res.error,

                () => console.log('user ok')
              );
            }
          });
        }
      });
    } else {
      // hur sessionen hanteras
      //1. kolla session i url, den gör override på allt annat. Verifiera och spara den om giltig, annars login
      //2. kolla session i cookie, verifiera och använd den.
      //3. ingen session hittats => login

      // 1. hämta s parametern ur URL (sessionen) och kolla om det är samma som i storen annars skicka till login
      const urlparams = new URLSearchParams(window.location.search);
      session = urlparams.get('s');

      //om support loggar in som annan
      if (url.includes('cookies=false')) {
        this.setCookie = false;
      }
      var user = urlparams.get('user');
      if (user) this.$store.dispatch('infopingstore/set_temp_user', user);

      // 2. cookie
      if (
        (!session || session == null) &&
        this.$cookies.isKey(COOKIE_NAME_SESSION)
      ) {
        session = this.$cookies.get(COOKIE_NAME_SESSION);
      }

      // validera session om vi hittat nån
      if (session && session != null) {
        var obj = this.default_obj();
        obj.Q.session = session;
        // obj = Q.clean_null_obj(obj);
        rest2Api.postBackEnd('verify_token_v3', obj).then((user) => {
          if (user.status == '1') {
            this.fetch_source_number(user.phone_number);
            this.$store.dispatch('azurestore/getQuickInfo', session);
            this.$store
              .dispatch('azurestore/getUserFromSession', session)
              .then((res) => {
                if (res && res.status == 0) {
                  this.alertDialogNoCancel(
                    'Kan inte hämta data',
                    'Felkod:' + res.error,
                    () => this.logout()
                  );
                }
              });
            this.$store.dispatch('infopingstore/addLocalUser');
            this.getUserInfo(session);
          } else {
            //no valid session or cookie
            this.urlHasSeesion = false;
            this.showLogin = true;
            this.$router.push({
              path: '/login',
            });
            this.$cookies.remove(COOKIE_NAME_SESSION);
          }
        });
      } else {
        // 3. ingen session hittad    => inloggning

        this.urlHasSeesion = false;
        this.showLogin = true;
        this.$router.push({
          path: '/login',
        });
        this.$cookies.remove(COOKIE_NAME_SESSION);
      }
    }
    //genererar error i consolen men verkar funka
    this.$vuetify.theme.themes.dark.primary = 'var(--v-primary-base)';
    this.$vuetify.theme.themes.light.primary = 'var(--v-primary-base)';
  },
  watch: {
    customerPrefix() {
      this.setMetaColors();
      //set branding
      var body = document.getElementsByTagName('body')[0];
      if (this.org == 'SMH') body.classList = ['smh'];
      else if (this.org == 'EO') body.classList = ['erikolsson'];
      else if (this.org == 'SKANDIA') body.classList = ['skandiamaklarna'];
      else if (this.org == 'MR') body.classList = ['maklarringen'];
      else if (this.org == 'HEMMA') body.classList = ['hemmaklarna'];
      else if (this.org == 'FASTIGHET') body.classList = ['fastighetsmaklarna'];
      else if (this.org == 'BOOSTAD' && this.theSource == 'Mspecs')
        body.classList = ['ipHomePageDark'];
      else if (this.org == 'IP') body.classList = ['ipHomePage'];
      else body.classList = ['boo'];

      if (this.customerPrefix == 'boo' && !this.org) {
        this.alertDialogNoCancel(
          'org saknas',
          'Vi hittar inte din profil. Kontakta Boostad Support: 018-681622, support@boostad.net',
          () => this.logout()
        );
      }

      //styr var man hamnar om url:en inte anger vilken sida man vill se
      var url = window.location.href;
      if (
        this.localUser &&
        !(
          url.includes('brokerWeb') ||
          url.includes('stats') ||
          url.includes('bidding') ||
          url.includes('nps') ||
          url.includes('shortcuts') ||
          url.includes('NPS_EO') ||
          url.includes('smartspec')
        )
      ) {
        if (this.org == 'EO') {
          this.$router.push({
            path: '/NPS_EO',
          });
        } else if (this.org == 'STADS' || this.org == 'ALICIA') {
          this.$router.push({
            path: '/nps',
          });
        } else if (this.localUser) {
          this.$router.push({
            path: '/brokerWeb',
          });
        }
      } else if (!this.localUser) {
        this.$router.push({
          path: '/mypages',
        });
      }

      //save customerprefix to cookie
      // if (this.customerPrefix == 'boo')
      //   this.$cookies.set(COOKIE_NAME_CUSTOMERPREFIX, this.org);
      // else this.$cookies.set(COOKIE_NAME_CUSTOMERPREFIX, this.customerPrefix);
    },
    support() {
      if (this.support == 'superagent') this.brokerLogin = true;
    },
  },
  computed: {
    ...mapGetters({
      customerPrefix: 'azurestore/customerPrefix',
    }),
    localUser() {
      return this.$store.state.infopingstore.localUser;
    },
    source() {
      return get(this.$store.state.azurestore, 'userInfo.source', 'Vitec');
    },
    // boostadCustomerId() {
    //   var id = get(this.$store.state.azurestore, 'userInfo.customerId', '');
    //   if (id) id = id.toLowerCase();
    //   return id;
    // },
    support() {
      return get(this.$store.state.infopingstore, 'user_type', '');
    },
    loadingPage() {
      return get(this.$store.state.azurestore, 'loading', true);
    },
  },
  methods: {
    getUserInfo(session) {
      this.$store.dispatch('infopingstore/addUserSession', session);
      this.$store.dispatch('infopingstore/check_access', session);
      if (this.setCookie) this.$cookies.set(COOKIE_NAME_SESSION, session);
    },
    async fetch_source_id(id) {
      await this.$store.dispatch('infopingstore/fetch_source_id', id);
    },
    async fetch_source_name(name) {
      await this.$store.dispatch('infopingstore/fetch_source_name', name);
    },
    async fetch_source_number(number) {
      await this.$store.dispatch('infopingstore/fetch_source_number', number);
    },

    setMetaColors() {
      //dark för MR, SKM, 08 Hemmäklarna och Boostad Mspecs

      if (this.org == 'MR' || this.org == 'SKANDIA' || this.org == 'HEMMA')
        this.$vuetify.theme.dark = true;
      else if (this.org == 'BOOSTAD' && this.theSource == 'Mspecs')
        this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;

      //    <!--<meta name="theme-color" content="#ff7a18">-->
      //obs! måste göras om när flera har boo som prefix, funkar inte att ta från css
      //verkar itne funka alls ibland???? när det funkar prova med 'var(--main-bg-color)' som en sträng
      var meta = '';
      if (this.org == 'SMH') {
        meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = '#dedbd5';
        document.getElementsByTagName('head')[0].appendChild(meta);
      } else {
        meta = document.createElement('meta');
        meta.name = 'theme-color';
        meta.content = '#121212';
        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    },
    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },

    logout() {
      this.$cookies.remove(COOKIE_NAME_SESSION);

      //this.$store.dispatch("infopingstore/addUserSession", null);
      this.$store.dispatch('infopingstore/clear_all');
      this.$store.dispatch('azurestore/clear_all');
      //   window.location.href=window.location.origin;
      window.location.href = '/';
    },
  },
};
</script>
<style>
#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  font-family: var(--body-font); /*'Lato', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /* background-color: #f5f5f5;*/
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#thecenter {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
