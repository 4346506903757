<template>
  <div>
    <v-container style="padding: 0px">
      <!--endast för support, för att kunna se objektsid-->
      <v-row v-if="showColumns">
        <span style="margin: 15px 35px 0px 15px; width: 100%">
          <v-select
            v-model="selectedHeaders"
            :items="headers"
            label="Välj kolumner"
            multiple
            outlined
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 5">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 5" class="grey--text caption"
                >(+{{ selectedHeaders.length - 5 }} others)</span
              >
            </template>
          </v-select>
        </span>
      </v-row>
      <v-row>
        <v-card flat class="cardStyle">
          <v-label v-if="loadingPage == true"
            >Laddar objekt från mäklarsystemet..</v-label
          >

          <v-skeleton-loader
            v-if="loadingPage == true"
            type="table"
          ></v-skeleton-loader>

          <v-row v-if="loadingPage == false">
            <!--lista de olika status som objekten har-->
            <v-select
              :items="estateTypes"
              label="Filtrera bostäder"
              v-model="selectedEstateType"
              item-value="status"
              item-text="name"
              return-object
              style="margin: 10px"
            ></v-select>
            <!--sök-->
            <!--style="max-width: 600px; padding-top: 0px"-->

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Sök"
              single-line
              hide-details
              style="margin: 10px"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-data-table
              class="row-pointer"
              style="width: 100%"
              :search="search"
              v-if="loadingPage == false"
              single-select
              @click:row="rowClick"
              :headers="showHeaders"
              :items="brokerEstates"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              no-data-text="Inga bostäder"
              :footer-props="{
                'items-per-page-text': 'Objekt per sida',
              }"
            ></v-data-table>
          </v-row>
        </v-card>
      </v-row>
      <!--Osynlig knapp för att kunna ta fram objektsid-->
      <v-btn text @click="showColumns = !showColumns"></v-btn>
    </v-container>
  </div>
</template>

<script>
//import azureApi from '@/api/azureApi.js';
import mixin from '@/mixin.js';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
export default {
  name: 'EstateList',
  mixins: [mixin],
  props: { module: String },
  data() {
    return {
      sortBy: 'ChangedDate',
      sortDesc: true,
      loadingPage: true,
      selectedEstate: {},
      showColumns: false,
      selectedHeaders: [],
      selectedEstateType: {},
      search: '',
      headers: [
        { text: 'Adress', value: 'Address.StreetAddress' },
        { text: 'ObjektsID', value: 'ExternalIDs[0].Id' },
        { text: 'Objektsnr', value: 'EstateNumber' },
        {
          text: 'Stad',
          value: 'Address.City',
          sortable: true,
        },
        {
          text: 'Ändrad',
          value: 'ChangedDate',
          sortable: true,
        },
      ],

      estates: [],
      brokerID: '',
    };
  },
  components: {},
  watch: {
    estateTypes() {
      //sätt initala värdet på filtret
      if (!this.estateTypes.some((status) => status.name === 'Till salu')) {
        this.selectedEstateType = {
          name: 'Alla objekt',
          value: 'Alla objekt',
        };
      } else {
        if (this.source == 'Mspecs')
          this.selectedEstateType = {
            name: 'Till salu',
            value: 'ENUMS_OBJECTSTATUS_TYPE_FOR_SALE',
          };
        else
          this.selectedEstateType = { name: 'Till salu', value: 'Till salu' };
      }
    },
    userInfo() {
      //jwttoken och övrig user info finns inte alltid direkt om man kommer via mäklarsystemet
      this.$store
        .dispatch('azurestore/getBrokerEstates', this.userInfo)
        .then((res) => {
          this.loadingPage = false;
          if (res && res.status == 0) {
            this.alertDialogNoCancel(
              'Fel',
              'Kunde inte hämta bostäder: ' + res.error,

              () => console.log('user ok')
            );
          }
        });
    },
    brokerEstates() {
      this.loadingPage = false;
    },
  },
  created() {
    this.selectedHeaders = this.headers.filter(
      (header) =>
        header.text == 'Adress' ||
        header.text == 'Stad' ||
        header.text == 'Ändrad'
    );
  },
  beforeMount() {
    this.$store.dispatch('azurestore/clear_SO');

    //hämta alltid bostäderna  för att det ska bli färsk data (budgivning kan tex ha pausats)
    //skulle kunna hämta enskild bostad när vi går in på den istället men bättre att laddtiden är här...?
    if (this.userInfo.boostad_admin) {
      this.$store
        .dispatch('azurestore/getBrokerEstates', this.userInfo)
        .then((res) => {
          this.loadingPage = false;
          if (res && res.status == 0) {
            this.alertDialogNoCancel(
              'Fel',
              'Kunde inte hämta bostäder: ' + res.error,

              () => console.log('user ok')
            );
          }
        });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      customerPrefix: 'azurestore/customerPrefix',
    }),

    source() {
      return get(this.$store.state.azurestore, 'userInfo.source', null);
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    userInfo() {
      return get(this.$store.state.azurestore, 'userInfo', {});
    },
    // brokerEstates() {
    //   var bs = get(this.$store.state.azurestore, 'brokerEstates', []);
    //   if (bs && bs.length > 0) {
    //     return bs.map((b) => {
    //       return { ...b, ChangedDate: b?.ChangedDate?.substring(0, 10) };
    //     });
    //   } else return [];
    // },
    brokerEstates() {
      var bs = get(this.$store.state.azurestore, 'brokerEstates', []);
      var filteredEstates = '';
      if (bs && bs.length > 0) {
        // Filter estates based on the selected status
        //  const selectedEstateType
        if (this.selectedEstateType.value != 'Alla objekt') {
          filteredEstates = bs.filter(
            (estate) => estate.Status === this.selectedEstateType.value
          );
        } else {
          filteredEstates = bs.filter(
            (estate) => estate.Status !== 'Alla objekt'
          );
        }

        // ta bort tid i datumet
        return filteredEstates.map((b) => {
          return { ...b, ChangedDate: b?.ChangedDate?.substring(0, 10) };
        });
      } else {
        return [];
      }
    },

    estateTypes() {
      var estates = get(this.$store.state.azurestore, 'brokerEstates', []);

      if (estates && estates.length > 0) {
        // Hitta alla typer av status på objekten
        const uniqueStatuses = [
          ...new Set(estates.map((estate) => estate.Status)),
        ];

        // Map unique statuses to objects with name and value properties
        const statusObjects = uniqueStatuses.map((status) => ({
          name: this.translateStatus(status), // översätt för Mspecs har konstiga statusar
          value: status,
        }));

        statusObjects.unshift({ name: 'Alla objekt', value: 'Alla objekt' });
        return statusObjects;
      } else {
        return [];
      }
    },
  },
  methods: {
    rowClick(item, row) {
      this.$store.dispatch('azurestore/clearImageUrl');
      row.select(true);
      this.$store.dispatch('azurestore/localEstate', item).then((res) => {
        if (res && res.status == 0) {
          if (this.module == 'bidding') {
            this.alertDialogNoCancel(
              '',
              'Kan inte hämta bostaden: ' + res.error,

              () =>
                this.$router.push({
                  path: '/bidding',
                })
            );
          } else {
            this.alertDialogNoCancel(
              '',
              'Kan inte hämta bostaden: ' + res.error,

              () =>
                this.$router.push({
                  path: '/brokerWeb',
                })
            );
          }
        }
      });
      if (this.module == 'bidding') {
        // this.$router.push({
        //   path: '/bidding',
        // });
        this.$emit('estateSelected');
      } else if (this.module == 'brokerWeb')
        this.$router.push({
          path: '/mypages',
        });

      this.selectedEstate = item;
    },

    // Function to translate status
    translateStatus(status) {
      switch (status) {
        case 'ENUMS_OBJECTSTATUS_TYPE_INTAKE':
          return 'Under intag';
        case 'ENUMS_OBJECTSTATUS_TYPE_SOLD':
          return 'Såld';
        case 'ENUMS_OBJECTSTATUS_TYPE_IN_PROGRESS':
          return 'Pågående';
        case 'ENUMS_OBJECTSTATUS_TYPE_FOR_SALE':
          return 'Till salu';
        case undefined:
          return 'Ingen status hittad';
        case 'UNKNOWN':
          return 'Ingen status hittad';
        case 'ENUMS_OBJECTSTATUS_TYPE_ARCHIVED':
          return 'Arkiverat';
        case 'ENUMS_OBJECTSTATUS_TYPE_ASSIGNMENT_SIGNED':
          return 'Uppdrag påskrivet';
        case 'ENUMS_OBJECTSTATUS_TYPE_AVAILABLE FOR RENT':
          return 'Till salu';
        case 'ENUMS_OBJECTSTATUS_TYPE_FOR_RENT':
          return 'Hyresledig';
        case 'ENUMS_OBJECTSTATUS_TYPE_CLOSED':
          return 'Avslutat';
        case 'ENUMS_OBJECTSTATUS_TYPE_COMING':
          return 'Kommande';
        case 'ENUMS_OBJECTSTATUS_TYPE_DORMANT':
          return 'Vilande';
        case 'ENUMS_OBJECTSTATUS_TYPE_ESTIMATE':
          return 'Värdering';
        case 'ENUMS_OBJECTSTATUS_TYPE_LEASED':
          return 'Uthyrd';
        case 'ENUMS_OBJECTSTATUS_TYPE_LOST_LISTING':
          return 'Förlorat intag';
        case 'ENUMS_OBJECTSTATUS_TYPE_MARKETING':
          return 'Annonsering';
        case 'ENUMS_OBJECTSTATUS_TYPE_NO_ASSIGNMENT':
          return 'Inget uppdrag';
        case 'ENUMS_OBJECTSTATUS_TYPE_REPOSSESSED':
          return 'Återtagen';
        case 'ENUMS_OBJECTSTATUS_TYPE_OTHER':
          return 'Annat';
        case 'ENUMS_OBJECTSTATUS_TYPE_REFERENCE':
          return 'Referensobjekt';
        case 'ENUMS_OBJECTSTATUS_TYPE_TERMINATED':
          return 'Uppsagt';
        default:
          return status; // Default to the original status if no translation is found
      }
    },
  },
};
</script>
<style>
.headerStyle {
  font-size: 35px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #999;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(45px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: 'AV';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: 'PÅ';
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
/*--------- END --------*/
</style>
