import { URL_IP, QUERY_URL } from '@/constants.js';

import axios from 'axios';
import get from 'lodash/get';
import { get_header } from '../components/f';

export default {
  async postBackEnd(funktion, obj) {
    const headers = get_header();
    var post_data = JSON.stringify(obj);

    const url = QUERY_URL + funktion;
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
        return get(res, 'data.A', []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async postBackEndNoFunc(funktion, obj) {
    const headers = get_header();
    var post_data = JSON.stringify(obj);

    const url = URL_IP + funktion;
    //console.log('api', url, post_data);
    return await axios
      .post(url, post_data, {
        headers: headers,
      })
      .then((res) => {
       //console.log('svar', res);
        return get(res, 'data', []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async getBackEndNoFunc(funktion) {
    //const headers = get_header();
    const url = URL_IP + funktion;
    return await axios
      .get(url, {
        headers: {
          Accept: 'text/event-stream',
          responseType: 'stream',
        },
      })
      .then((res) => {
        return get(res, 'data', []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  // async checkImageUrlWithAxios(url, placeholderUrl) {
  //  console.log('axios', url, placeholderUrl);

  // axios
  //   .get(url, {
  //     responseType: 'stream',
  //   })
  //   .then((response) => {
  //     // Image loaded successfully
  //     console.log('ll', url);
  //     return response;
  //   })
  //   .catch((error) => {
  //     // Image failed to load, use placeholder
  //     console.log('hh', placeholderUrl);
  //     return error;
  //   });
  // },
};
