export const ESTATE = 'ESTATE';
export const SERVICE_ORDER = 'SERVICE_ORDER';
export const BROKER_ESTATES = 'BROKER_ESTATES';
export const SUPPORT_INFO = 'SUPPORT_INFO';
export const NPS_DATA = 'NPS_DATA';
export const USER_INFO = 'USER_INFO';
export const VITEC_URL = 'VITEC_URL';
export const LOADING = 'LOADING';
export const SUB_LOADER = 'SUB_LOADER';
export const ESTATE_SETTINGS_VISIBILITY = 'ESTATE_SETTINGS_VISIBILITY';
export const IMG_URL = 'IMG_URL';

