<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />
      <v-row>
        <v-card flat class="cardStyle">
          <!-- <v-label v-if="loadingPage == true">Laddar NPS-data..</v-label>
          <v-skeleton-loader
            v-if="loadingPage == true"
            type="table"
          ></v-skeleton-loader>-->
          <div>
            <v-row>
              <v-label style="padding: 20px">FILTER</v-label>
            </v-row>
            <br />
            <v-row>
              <v-radio-group v-model="selectedType" row>
                <v-radio label="NPS" :value="1"></v-radio>
                <v-radio label="Kommentarer" :value="2"></v-radio>
                <v-radio label="Statistik" :value="3"></v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-select
                  dense
                  label="Kontor"
                  outlined
                  :items="offices"
                  item-text="customer_name"
                  item-value="customerId"
                  return-object
                  v-model="selectedOffice"
                  @change="handleSelectionChangeOffices"
                ></v-select>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-select
                  dense
                  label="Mäklare"
                  outlined
                  item-text="broker_name"
                  item-value="brokerId"
                  return-object
                  :items="filteredBroker"
                  v-model="selectedBroker"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  ref="menuFrom"
                  v-model="menuFrom"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      v-model="dateFrom"
                      outlined
                      label="Fr.o.m"
                      append-icon="arrow_drop_down"
                      v-on="on"
                      @click:append="on.click"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    @input="menuFrom = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  ref="menuTo"
                  v-model="menuTo"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      v-model="dateTo"
                      outlined
                      label="T.o.m"
                      append-icon="arrow_drop_down"
                      @click:append="on.click"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    @input="menuTo = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <v-select
                  dense
                  label="Objektstyp"
                  outlined
                  item-text="name"
                  item-value="estate_subType"
                  return-object
                  :items="estateTypes"
                  v-model="selectedEstateType"
                ></v-select>
              </v-col>

              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <v-select
                  dense
                  label="Enkät"
                  outlined
                  :items="surveyType"
                  v-model="selectedSurveyType"
                ></v-select>
              </v-col>

              <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                <v-select
                  dense
                  label="Part"
                  outlined
                  :items="part"
                  v-model="selectedPart"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <!--     NPS -->

        <template v-if="selectedType == 1">
          <v-card flat class="cardStyle">
            <span class="EOmainContainer">
              <v-row>
                <v-label>NPS</v-label>
              </v-row>
              <span class="EOspinnerContent">
                <div
                  v-if="subLoader"
                  class="EOspinnerOverlay"
                  justify-content="center"
                >
                  <Spinner />
                </div>
              </span>
            </span>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <br />
                <v-label>Totalt antal svar: </v-label>
                <v-label>{{ this.nbrAnswers }}</v-label>
                <br />
                <v-label>Ambassadörer: </v-label>
                <v-label>{{ this.nbrPromoters }}</v-label>
                <br />
                <v-label>Passiva: </v-label>
                <v-label>{{ this.nbrPassives }}</v-label>
                <br />
                <v-label>Kritiker: </v-label>
                <v-label>{{ this.nbrDetractors }}</v-label> <br />
                <br />
                <b>
                  <v-label>NPS-värde: </v-label>
                  <v-label>{{ this.npsScore }}</v-label> <br
                /></b>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <div>
                  <DoughnutChart
                    :width="200"
                    :height="200"
                    :data="chartDataNPS"
                    :backgroundColor="NPSColor"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat class="cardStyle">
            <v-row>
              <v-label>FöRDELNING AV NPS-SVAR</v-label>
            </v-row>
            <br />
            <br />
            <div>
              <BarChartH
                :height="250"
                :data="chartDataNpsBar"
                :backgroundColor="NpsBarColor"
                :labels="NpsBarLabels"
              />
            </div>
          </v-card>
        </template>

        <!--   KOMMENTARER -->
        <template v-if="selectedType == 2">
          <v-card flat class="cardStyle">
            <span class="EOmainContainer">
              <v-row>
                <v-label>KOMMENTARER</v-label>
              </v-row>
              <span class="EOspinnerContent">
                <div
                  v-if="subLoader"
                  class="EOspinnerOverlay"
                  justify-content="center"
                >
                  <Spinner />
                </div>
              </span>
            </span>
            <br />
            <template>
              <v-data-table
                :headers="commentHeaders"
                :items="surveyComments"
                density="compact"
                @click:row="rowClick"
                no-data-text="Inga kommentarer"
                :footer-props="{
                  'items-per-page-text': 'Kommentarer per sida',
                }"
              ></v-data-table>
            </template>
          </v-card>
        </template>
        <!-- STATISTIK -->
        <v-container v-if="selectedType == 3">
          <v-row v-if="statsArray">
            <!-- loopa genom stats array och gör charts för varje fråga -->
            <template v-for="(item, index) in statsArray">
              <v-col :key="index" cols="12" xl="6" lg="6" md="6" sm="6">
                <v-card style="margin-left: 0px" flat class="cardStyle">
                  <v-row justify="center">
                    <v-label>{{ item.header }}</v-label>
                  </v-row>

                  <v-row justify="center">
                    <div>
                      <BarChartH
                        :height="200"
                        :data="item.data"
                        :backgroundColor="statsBarColor"
                        :labels="item.labels"
                        :width="320"
                      />
                    </div>
                  </v-row>

                  <v-row justify="center">
                    <v-label
                      ><span class="gradeLabel"
                        >Antal svar: {{ item.sum }}
                      </span>
                    </v-label>
                    <v-label v-if="item.average != ''"
                      ><span class="gradeLabel"
                        >Medelbetyg: {{ item.average }}</span
                      ></v-label
                    >
                  </v-row>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-row>
    </v-container>

    <!-- dialog visa kommentar  -->
    <v-dialog v-model="commentDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <br />
          <span class="boxText">
            <b> Kommentar:</b> {{ this.currentComment.json }}
          </span>
          <br />
          <br />
          <span class="boxText">
            <b>Mäklare:</b> {{ this.currentComment.broker_name }}
          </span>
          <br />
          <br />
          <span class="boxText">
            <b>Objekt:</b> {{ this.currentComment.estate_streetAddress }}
          </span>
          <br />
          <br />
          <span class="boxText">
            <b>Enkät:</b> {{ this.commentSurveyType }}
          </span>
          <br />

          <br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeComment()" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import BarChartH from '@/components/charts/BarChartHorizontal.vue';
//import PieChart from '@/components/charts/PieChart.vue';
import DoughnutChart from '@/components/charts/Doughnut.vue';
import Description from '@/components/description.vue';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import Spinner from '@/components/spinners/dualRing.vue';
//import Bar2 from '@/components/charts/Bar2.vue';

export default {
  name: 'NPS',
  mixins: [mixin],

  data() {
    return {
      initializedOffices: false, // Flag to track initialization
      initializedBrokers: false, // Flag to track initialization
      dateFrom: this.getDateFrom(),
      menuFrom: false,
      dateTo: this.getDateTo(),
      menuTo: false,
      selectedBroker: '',
      selectedOffice: '',
      selectedEstateType: '',
      selectedPart: '',
      selectedSurveyType: '',
      part: ['Alla', 'Köpare', 'Säljare', 'Visningsdeltagare'],
      surveyType: ['Alla', 'Kontrakt', 'Tillträde', 'Visning'],

      NPSColor: ['#41B883', '#e6b400', '#E46651'], //#41B883', '#E46651', '#00D8FF
      chartDataNPS: [0, 0, 0], //promoters, passives, detractors
      selectedType: 1,
      npsScore: null,
      nbrAnswers: 0,
      nbrPromoters: 0,
      nbrPassives: 0,
      nbrDetractors: 0,
      NpsBarColor: [
        '#41B883',
        '#41B883',
        '#e6b400',
        '#e6b400',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
        '#E46651',
      ],
      NpsBarLabels: ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
      chartDataNpsBar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      statsBarColor: ['#bebebe'],
      gradeLabel: [5, 4, 3, 2, 1],
      statsArray: [],
      gradeQuestions: [],
      commentDialog: false,
      currentComment: [],
      commentSurveyType: '',
      header: 'NPS',
      description: 'Se de betyg som kunderna lämnat om er och er mäklartjänst.',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_graphpen.jpg',
      commentHeaders: [
        // { text: this.getLang('v5_groups', 'lbl_member'), value: 'user_name' },

        { text: 'Svar inkommet', value: 'date_time' },
        { text: 'NPS-svar', value: 'nps_answer', sortable: false },
        { text: 'Kommentar', value: 'json' },
      ],
      surveyComments: [],
    };
  },
  watch: {
    session() {
      if (this.session != null) this.getNPSData();
    },
    selectedType() {
      if (this.selectedType == 1) this.calculateNPSData();
      if (this.selectedType == 2) this.filterComments();
      if (this.selectedType == 3) this.calculateStats();
    },

    offices() {
      if (!this.initializedOffices) {
        // Value changed after initialization
        //to prevent resetting chosed alternatives
        this.selectedOffice = this.offices[0];
        this.initializedOffices = true;
      } else if (this.initializedOffices && !this.selectedOffice)
        this.selectedOffice = this.offices[0];
    },
    brokers() {
      if (!this.initializedBrokers) {
        // Value changed after initialization

        this.selectedBroker = this.brokers[0];
        this.initializedBrokers = true;
      } else if (this.initializedOffices && !this.selectedBroker)
        this.selectedBroker = this.brokers[0];
    },
    estateTypes() {
      this.selectedEstateType = this.estateTypes[0];
    },
    selectedSurveyType(val) {
      if (val && val == 'Visning') this.selectedPart = 'Visningsdeltagare';
      else if (
        val &&
        val != 'Visning' &&
        this.selectedPart == 'Visningsdeltagare'
      )
        this.selectedPart = 'Alla';
    },
    selectedPart(val) {
      if (val && val == 'Visningsdeltagare')
        this.selectedSurveyType = 'Visning';
      else if (
        val &&
        val != 'Visningsdeltagare' &&
        this.selectedSurveyType == 'Visning'
      )
        this.selectedSurveyType = 'Alla';
    },
    filteredList() {
      if (this.selectedType == 1) this.calculateNPSData();
      if (this.selectedType == 2) this.filterComments();
      if (this.selectedType == 3) this.calculateStats();
    },
    dateFrom(val) {
      if (val) {
        this.getNPSData();
      }
    },
    dateTo(val) {
      if (val) {
        this.getNPSData();
      }
    },
  },
  beforeMount() {},
  mounted() {
    this.selectedSurveyType = this.surveyType[0];
    this.selectedPart = this.part[0];

    this.getNPSData();
  },
  components: { BarChartH, DoughnutChart, Description, Spinner },
  computed: {
    ...mapGetters({
      offices: 'azurestore/surveyOffices',
      brokers: 'azurestore/surveyBrokers',
      estateTypes: 'azurestore/surveyEstateTypes',
      surveyData: 'azurestore/surveyData',
      surveyAnswers: 'azurestore/surveyAnswers',
    }),
    subLoader() {
      return get(this.$store.state.azurestore, 'subLoader', false);
    },
    filteredList() {
      if (this.surveyData && this.surveyData.length > 0) {
        var filteredData = this.surveyData;

        if (this.selectedOffice.customerId != '000000')
          filteredData = filteredData.filter((a) =>
            a.customerId.includes(this.selectedOffice.customerId)
          );

        if (this.selectedBroker.brokerId != '000000')
          filteredData = filteredData.filter((a) =>
            a.brokerId.includes(this.selectedBroker.brokerId)
          );

        if (this.selectedEstateType.estate_subType != '000000')
          filteredData = filteredData.filter((a) =>
            a.estate_subType.includes(this.selectedEstateType.estate_subType)
          );

        if (this.selectedSurveyType != 'Alla') {
          if (this.selectedSurveyType == 'Tillträde')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('AccessDate')
            );
          else if (this.selectedSurveyType == 'Kontrakt')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('SignatureDate')
            );
          else if (this.selectedSurveyType == 'Visning')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('ViewingDate')
            );
        }

        if (this.selectedPart != 'Alla') {
          if (this.selectedPart == 'Säljare')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('Seller')
            );
          else if (this.selectedPart == 'Köpare')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('Buyer')
            );
          else if (this.selectedPart == 'Visningsdeltagare')
            filteredData = filteredData.filter((a) =>
              a.vue_message.includes('Participant')
            );
        }

        // if (this.type == 0)
        //   data = data.filter((a) => a.type.includes('Nps värde'));

        return filteredData;
      } else return '';
    },

    filteredBroker() {
      if (this.selectedOffice == this.offices[0]) return this.brokers;
      else {
        return this.brokers.filter(
          (a) =>
            a.customerId == this.selectedOffice.customerId ||
            a.brokerId == '000000'
        );
      }
    },

    session() {
      //endast lokalt fixa ifall kommer från vitec

      return get(this.$store.state.azurestore.userInfo, 'session', null);
    },
  },
  methods: {
    handleSelectionChangeOffices() {
      // Access the selected value using this.selectedValue
      this.selectedBroker = this.brokers[0];
    },
    getNPSData() {
      this.$store.dispatch('azurestore/changeSubLoader', true);
      if (this.org_key == '2673' || this.org_key == '1330')
        this.$store.dispatch('azurestore/npsDemoData');
      else {
        //lägg till en dag för att få med sista dagen i intervallet, johan räknar less ist för t.o.m.
        var newDate = new Date(this.dateTo);
        newDate.setDate(newDate.getDate() + 1);

        var obj = new Object();
        obj.date_from = this.dateFrom;
        obj.date_less = newDate.toISOString().slice(0, 10);
        this.$store.dispatch('azurestore/getNPSData', obj).then((res) => {
          if (res == 'no answers')
            this.alertDialogNoCancel(
              'Inga svar',
              'Inga svar har inkommit under angiven period. Prova annat datumintervall',
              () => console.log('user ok')
            );
          else if (res == 'error')
            this.alertDialogNoCancel(
              'Ingen data hittades',
              'Försök med andra datum',
              () => console.log('user ok')
            );
        });
      }
    },

    // fetchDemoData() {
    //   if (this.org_key == '2673' || this.org_key_ip == '1330')
    //     this.$store.dispatch('azurestore/npsDemoData');
    //   else if (this.org_key_ip == '2673' || this.org_key_ip == '1330')
    //     this.$store.dispatch('azurestore/npsDemoData');
    // },

    getDateTo() {
      var d = new Date();

      d = d.toISOString().slice(0, 10);

      return d;
    },

    getDateFrom() {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      d = d.toISOString().slice(0, 10);

      return d;
    },

    calculateNPSData() {
      this.nbrPromoters = 0;
      this.nbrPassives = 0;
      this.nbrDetractors = 0;
      this.nbrAnswers = 0;
      this.npsScore = 0;
      this.chartDataNPS = [0, 0, 0];
      var nps_value = 0;
      var chartBar = [];
      this.chartDataNpsBar = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (this.filteredList && this.filteredList.length > 0) {
        this.filteredList.forEach((element) => {
          nps_value += element.nps_value;
          if (element.nps_value == 100) this.nbrPromoters += 1;
          else if (element.nps_value == 0) this.nbrPassives += 1;
          else if (element.nps_value == -100) this.nbrDetractors += 1;
        });

        this.npsScore = Math.round(nps_value / this.filteredList.length);
        this.nbrAnswers = this.filteredList.length;
        this.chartDataNPS = [
          this.nbrPromoters,
          this.nbrPassives,
          this.nbrDetractors,
        ];
        for (var i = 10; i >= 0; i--)
          chartBar.push(
            this.filteredList.filter((a) => a.nps_answer === i).length
          );

        this.chartDataNpsBar = chartBar;
      } else console.log('ingen träff');
      this.$store.dispatch('azurestore/changeSubLoader', false);
    },

    filterComments() {
      this.surveyComments = [];
      var comments = [];
      var surveys = [];
      var answers = [];

      if (this.filteredList && this.filteredList.length > 0) {
        this.filteredList.forEach((element) => {
          if (!surveys.some((item) => item === element.hex))
            surveys.push(element.hex);
        });
      }

      this.surveyAnswers.forEach((answer) => {
        if (surveys.some((item) => item == answer.hex)) answers.push(answer);
      });
      if (answers && answers.length > 0) {
        answers.forEach((message) => {
          if (message.type == 'Fritext') comments.push(message);
        });
      }

      for (let i = 0; i < comments.length; i++) {
        this.surveyComments.push({
          ...comments[i],
          json: comments[i].json.replaceAll('\\n', '').replaceAll('"', ''),
          ...this.surveyData.find(
            (itmInner) => itmInner.hex === comments[i].hex
          ),
        });
      }
      this.$store.dispatch('azurestore/changeSubLoader', false);
    },

    calculateStats() {
      var surveys = [];
      var answers = [];
      //töm statistiken från förra filtreringen
      this.statsArray = [];

      //hämta vilka enkäter (hex) som finns i den filtrerade listan
      if (this.filteredList && this.filteredList.length > 0) {
        this.filteredList.forEach((element) => {
          if (!surveys.some((item) => item === element.hex))
            surveys.push(element.hex);
        });
      }

      // hämta alla svar till dessa enkäter
      this.surveyAnswers.forEach((answer) => {
        if (surveys.some((item) => item == answer.hex)) answers.push(answer);
      });

      if (answers && answers.length > 0) {
        //räkna ut de specifika statsen
        this.brokerGrade(answers);
        this.reasonToHire(answers);
        this.bidding(answers);
      }
      this.$store.dispatch('azurestore/changeSubLoader', false);
    },

    brokerGrade(answers) {
      this.gradeQuestions = [];

      //hitta alla frågor kring mäklarens betyg
      answers.forEach((element) => {
        if (element.type == 'Mäklarens betyg')
          if (!this.gradeQuestions.some((item) => item === element.info))
            this.gradeQuestions.push(element.info);
      });

      this.gradeQuestions.forEach((header) => {
        //filtrera svaren, hitta betygen för varje fråga
        const filteredAnswers = answers
          .filter((a) => a.info.includes(header))
          .map((c) => parseInt(c.json));

        //loopa genom betygen, filtrera svaren efter betyg 5, räkna (length), sen 4 osv
        var chartData = [];
        var totalAnsw = 0;
        var average = 0;

        for (var i = 5; i >= 1; i--)
          chartData.push(filteredAnswers.filter((a) => a === i).length);

        //beräkna medelvärdet summera med avancerad magi
        const sum = filteredAnswers.reduce((a, b) => a + b, 0);
        const avg = sum / filteredAnswers.length || 0;
        // console.log('medel', avg.toFixed(1), filteredAnswers.length);
        totalAnsw = filteredAnswers.length;
        average = avg.toFixed(1);

        //byt till kortare headers:
        if (header.includes('köpekontraktshandlingarna inför'))
          header = 'Förklaring av köpekontraktshandlingar';
        else if (header.includes('presenterade mäklartjänsten'))
          header = 'Presentation av mäklartjänsten';
        else if (header.includes('bostaden presenterades av'))
          header = 'Presentation av bostaden';
        else if (header.includes('upplevde du första kontakten med'))
          header = 'Första kontakten';
        else if (
          header.includes('upplevde du informationen från') &&
          header.includes('inför och efter visningen')
        )
          header = 'Information före och efter visningen';

        //pusha till statsArray

        this.statsArray.push({
          data: chartData,
          labels: this.gradeLabel,
          header: header.toUpperCase(),
          sum: totalAnsw,
          average: average,
        });
      });
      // console.log('statsArray', this.statsArray);
    },

    reasonToHire(answers) {
      var reason = [];
      //  var theAnswer = [];
      var totalAnsw = 0;

      // hur gör man sen då????
      answers.forEach((element) => {
        if (element.type == 'Anledning') {
          if (!reason.some((item) => item === element.json))
            reason.push(element.json);
        }
      });

      if (reason && reason.length) totalAnsw = reason.length;

      var count = [];
      var header = [];

      reason.forEach((val) => {
        header.push(val);
        count.push(answers.filter((obj) => obj.json === val).length);
      });

      // reason.forEach((val) => {
      //   count.push({
      //     answer: val,
      //     nbr: answers.filter((obj) => obj.json === val).length,
      //   });
      // });
      if (totalAnsw && totalAnsw > 0) {
        this.statsArray.push({
          data: count,
          labels: header,
          // [
          //   'Tidigare kontakt med mäklare',
          //   'Övrigt',
          //   'Rekommendation',
          //   'Bra marknadsmaterial',
          //   'Bra visningar',
          //   'Varumärket',
          // ],
          header: 'FRÄMSTA SKÄLET TILL ATT DU ANLITADE OSS',
          sum: totalAnsw,
          average: '',
        });
      }
    },
    bidding(answers) {
      var info = [];
      var totalAnsw = 0;

      answers.forEach((element) => {
        if (element.type == 'Budgivning') {
          if (!info.some((item) => item === element.json))
            info.push(element.json);
        }
      });

      if (info && info.length) totalAnsw = info.length;

      var count = [];
      var header = [];

      info.forEach((val) => {
        header.push(val);
        count.push(answers.filter((obj) => obj.json === val).length);
      });

      // reason.forEach((val) => {
      //   count.push({
      //     answer: val,
      //     nbr: answers.filter((obj) => obj.json === val).length,
      //   });
      // });
      if (totalAnsw && totalAnsw > 0) {
        this.statsArray.push({
          data: count,
          labels: header,
          header: 'FICK DU LÖPANDE INFORMATION OM BUDGIVNINGEN',
          sum: totalAnsw,
          average: '',
        });
      }
    },

    rowClick(item) {
      this.currentComment = item;

      if (this.currentComment.vue_message == 'EO_Seller_SignatureDate')
        this.commentSurveyType = 'Säljare efter kontrakt';
      if (this.currentComment.vue_message == 'EO_Buyer_SignatureDate')
        this.commentSurveyType = 'Köpare efter kontrakt';
      if (this.currentComment.vue_message == 'EO_Seller_AccessDate')
        this.commentSurveyType = 'Säljare efter tillträde';
      if (this.currentComment.vue_message == 'EO_Buyer_AccessDate')
        this.commentSurveyType = 'Köpare efter tillträde';
      if (this.currentComment.vue_message == 'EO_Participant_ViewingDate')
        this.commentSurveyType = 'Visningsdeltagare';

      this.commentDialog = true;
    },
    closeComment() {
      this.commentDialog = false;
      this.currentComment = [];
      this.commentSurveyType = '';
    },
  },
};
</script>
<style scoped>
.gradeLabel {
  font-size: 14px !important;
  padding-left: 5px;
}
.v-menu__content {
  background-color: var(--calendar-background);
}
</style>
