import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    estate: {
      Sellers: [],
      Settings: {
        VisibilityForSeller: null,
        VisibilityForBuyer: null,
        VisibilityForInterests: null,
        VisibleForSeller: null,
        VisibleForBuyer: null,
        VisibleForInterests: null,
      },
    },

    brokerEstates: [],
    supportInfo: {},
    npsData: {},
    userInfo: {},
    loading: false,
    subLoader: false,
    serviceOrder: null,
    imgUrl: null,
  },
  actions,
  mutations,
  getters,
};
