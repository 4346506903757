import Vue from 'vue';
import Vuex from 'vuex';
import infopingstore from './infopingstore';
import azurestore from './azurestore';

Vue.use(Vuex);

export default () =>
  new Vuex.Store({
    modules: { infopingstore, azurestore },
    strict: process.env.NODE_ENV !== 'production',
  });
