<template>
  <div class="topHeader">
    <v-img class="logo"></v-img>
    <span class="menu-position">
      <div class="menu">
        <span class="active-menu">ADMINPORTAL</span
        ><span class="inactive-menu"
          ><a :href="link" target="_blank" rel="noreferrer"
            >INSTRUKTIONER</a
          ></span
        >
        <span class="inactive-menu"
          ><a @click="this.supportInfo">KONTAKT</a></span
        >
        <span class="inactive-menu"><a @click="logout()">LOGGA UT</a></span>
      </div>
    </span>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
//import get from 'lodash/get';
export default {
  name: 'TopHeader',
  mixins: [mixin],
  data() {
    return {};
  },
  computed: {
    link() {
      if (this.theSource == 'Mspecs')
        return 'https://boostad.net/instruktioner/mspecs';
      else return 'https://boostad.net/instruktioner/vitec';
    },
  },
  beforeMount() {},
  methods: {},
};
</script>

<style scoped></style>
