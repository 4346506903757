import get from 'lodash/get';
import {
  BROKER_ESTATES,
  ESTATE,
  SERVICE_ORDER,
  SUPPORT_INFO,
  USER_INFO,
  NPS_DATA,
  LOADING,
  SUB_LOADER,
  ESTATE_SETTINGS_VISIBILITY,
  IMG_URL,
} from './types.js';

export default {
  [ESTATE](state, payload) {
    state.estate = payload;
  },

  [SERVICE_ORDER](state, payload) {
    state.serviceOrder = payload;
  },

  [BROKER_ESTATES](state, payload) {
    state.brokerEstates = payload;
  },
  [SUPPORT_INFO](state, payload) {
    state.supportInfo = payload;
  },
  [USER_INFO](state, payload) {
    state.userInfo = payload;
  },
  [NPS_DATA](state, payload) {
    state.npsData = payload;
  },
  [LOADING](state, payload) {
    state.loading = payload;
  },
  [SUB_LOADER](state, payload) {
    state.subLoader = payload;
  },
  [ESTATE_SETTINGS_VISIBILITY](state, payload) {
    var currentSettings = get(state, 'estate.Settings', []);
    var newSettings = Object.assign(currentSettings, payload);
    //uppdaterar storen när vi ställt om access för köpare och säljare för att slippa hämta objektet igen
    if (newSettings.VisibilityForBuyer == 'Show')
      newSettings.VisibleForBuyer = true;
    if (newSettings.VisibilityForBuyer == 'Hide')
      newSettings.VisibleForBuyer = false;
    if (newSettings.VisibilityForSeller == 'Show')
      newSettings.VisibleForSeller = true;
    if (newSettings.VisibilityForSeller == 'Hide')
      newSettings.VisibleForSeller = false;
    state.estate.Settings = newSettings;
  },
  [IMG_URL](state, payload) {
    state.imgUrl = payload;
  },

  // [VITEC_URL](state, payload) {
  //   state.vitecURL = payload;
  // },
};
