import { render, staticRenderFns } from "./nps_items.vue?vue&type=template&id=80ffa6a8&scoped=true&"
import script from "./nps_items.vue?vue&type=script&lang=js&"
export * from "./nps_items.vue?vue&type=script&lang=js&"
import style0 from "./nps_items.vue?vue&type=style&index=0&id=80ffa6a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ffa6a8",
  null
  
)

export default component.exports