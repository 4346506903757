//----------------------------------------------
// SKARPA
//----------------------------------------------

export const URL_IP_FUNCTION =
  'https://infoping.net/REST2/ask/Q/json/?function=';
export const URL_IP = 'https://infoping.net/REST2/ask/Q/';
export const QUERY_URL_BOSTAD = 'https://infoping.net/REST2/ask/Q/bostadsite/';

//export const QUERY_URL_LITE = 'https://infoping.net/REST2/ask/Q/';

export const QUERY_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://infoping.net/REST2/ask/Q/json/?function='
    : 'https://infoping.net/REST2/ask/Q/json/?function='; //dev

//----------------------------------------------
// DEV
//----------------------------------------------

//export const URL_IP_FUNCTION = 'https://dev.infoping.se/REST2/ask/Q/json/?function=';
//export const URL_IP ='https://dev.infoping.se/REST2/ask/Q/';

//----------------------------------------------
// ALLA
//----------------------------------------------
export const COOKIE_NAME_SESSION = 'BoostadPortalSession';
export const COOKIE_NAME_CUSTOMERPREFIX = 'BoostadCustomer';
// vitec

export const BOOSTAD_API = 'https://boostadapi.azurewebsites.net/';
export const STATS_SERVER = 'https://statistik.boostad.net';
