<template>
  <div>
    <v-container>
      <Description
        :url="estateImage"
        :header="header"
        :description="description"
      />
      <v-row>
        <v-card flat class="cardStyle">
          <v-label v-if="loadingPage == true"
            >Laddar objektet från mäklarsystemet..</v-label
          >

          <v-skeleton-loader
            v-if="loadingPage == true"
            type="table"
          ></v-skeleton-loader>

          <div v-if="org == 'mr' && loadingPage == false">
            <v-row>
              <v-label>BOOSTADS TJÄNSTER</v-label>
              <v-spacer></v-spacer>
            </v-row>
            <br /><br />
            <v-row>
              <v-label
                >För närvarande är er organisation endast anslutna till Boostads
                Budgivning med BankID.<br />
              </v-label>
              <br />
            </v-row>
            <br />
            <br />
            <br />
            <br />
            <v-row>
              <v-label
                >Bugivning med BankID aktiveras i Vitec Express. <br /><br />
                Spekulanter -> Budgivning -> Inställningar för objektet ->
                Använd Budgivning med BankID<br />
                <br />
                Om budgivningen är aktiverad i Vitec Express kan du pausa den
                här till höger.
              </v-label>
              <br />
            </v-row>
          </div>
          <v-row>
            <div
              style="padding: 20px"
              v-if="org != 'mr' && loadingPage == false"
            >
              <v-row>
                <v-label
                  ><b>{{ estateAddress }}</b></v-label
                >
                <v-spacer></v-spacer>
              </v-row>
              <br /><br />
              <v-row>
                <v-label>
                  Köpare och säljare kan logga in på Mina sidor via er hemsida.
                  Du kan en inloggningslänk direkt till Mina sidor genom att
                  klicka på "dela".
                </v-label>
              </v-row>
              <br /><br />

              <v-row v-if="!$vuetify.breakpoint.xs">
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <b> <label>Part</label></b>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <b> <label class="secondaryText">Namn</label></b>
                </v-col>

                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <b> <label class="secondaryText">Dela länk</label></b>
                </v-col>

                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <b> <label class="secondaryText">Logga in som</label></b>
                </v-col>
              </v-row>

              <template v-for="item in sellers">
                <v-row align-content="start" :key="item.Uid">
                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <v-label>Säljare:</v-label>
                  </v-col>

                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <v-label>{{
                      item.FirstName + ' ' + item.LastName
                    }}</v-label>
                  </v-col>

                  <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                    <v-btn @click="prepareSMS(item, 'Sell')" text>
                      <v-icon
                        :disabled="setAccessSeller == false"
                        color="var(--icon-color)"
                        >share</v-icon
                      ></v-btn
                    >
                  </v-col>

                  <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                    <v-btn text @click="loginAsUser(item, 'Sell')">
                      <v-icon
                        :disabled="
                          setAccessSeller == false || item?.Ssn == null
                        "
                        color="var(--icon-color)"
                        >login</v-icon
                      ></v-btn
                    >
                  </v-col>
                </v-row>

                <template v-if="item.OnBehalfOf && item.OnBehalfOf.length > 0">
                  <v-row
                    v-for="onBehalfItem in item.OnBehalfOf"
                    :key="onBehalfItem.Uid"
                  >
                    <template v-if="typeof onBehalfItem.Details === 'object'">
                      <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <v-label>Ombud för {{ item.FirstName }}:</v-label>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                        <v-label>{{
                          onBehalfItem.Details?.FirstName +
                          ' ' +
                          onBehalfItem.Details?.LastName
                        }}</v-label>
                      </v-col>

                      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                        <v-btn
                          @click="prepareSMS(onBehalfItem.Details, 'Sell')"
                          text
                        >
                          <v-icon
                            :disabled="setAccessSeller == false"
                            color="var(--icon-color)"
                            >share</v-icon
                          ></v-btn
                        >
                      </v-col>

                      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                        <v-btn
                          text
                          @click="loginAsUser(onBehalfItem.Details, 'Sell')"
                        >
                          <v-icon
                            :disabled="
                              setAccessSeller == false ||
                              onBehalfItem.Details?.Ssn == null
                            "
                            color="var(--icon-color)"
                            >login</v-icon
                          ></v-btn
                        >
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </template>

              <v-row
                align-content="start"
                v-for="item in buyers"
                :key="item.Uid"
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-label>Köpare:</v-label>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-label>{{ item.FirstName + ' ' + item.LastName }}</v-label>
                </v-col>

                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <v-btn @click="prepareSMS(item, 'Buy')" text>
                    <v-icon
                      :disabled="setAccessBuyer == false"
                      color="var(--icon-color)"
                      >share</v-icon
                    ></v-btn
                  >
                </v-col>

                <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                  <v-btn text @click="loginAsUser(item, 'Buy')"
                    ><v-icon
                      :disabled="setAccessBuyer == false || item?.Ssn == null"
                      color="var(--icon-color)"
                      >login</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>

              <v-row style="padding-top: 30px">
                <!--  <v-col cols="12" xl="4" lg="4" md="4" sm="4">-->

                <v-divider style="padding-bottom: 30px"></v-divider>
              </v-row>

              <v-row> <v-label>DOKUMENT</v-label></v-row>
              <br /><br />

              <v-row>
                <v-label v-if="documents && documents.length > 0">
                  Välj i vilka steg i sälj- resp köpprocessen som dokumenten ska
                  synas
                </v-label>
                <v-label v-else>
                  Inga dokument synkade från mäklarsystemet</v-label
                >
              </v-row>

              <br /><br />

              <v-row v-if="!$vuetify.breakpoint.xs">
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <b> <label class="secondaryText"></label></b>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <b>
                    <label
                      v-if="documents && documents.length > 0"
                      class="secondaryText"
                      >Synlig för säljare i steg</label
                    ></b
                  >
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <b>
                    <label
                      v-if="documents && documents.length > 0"
                      class="secondaryText"
                      >Synlig för köpare i steg</label
                    ></b
                  >
                </v-col>
              </v-row>
              <v-row
                dense
                align-content="start"
                v-for="(item, index) in documents"
                :key="item.Uid"
              >
                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-label>{{ item.Name }}</v-label>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-select
                    v-model="docSeller[index]"
                    :items="stepsSeller"
                    :item-text="'name'"
                    :item-value="'value'"
                    multiple
                    dense
                    attached
                    outlined
                    @blur="sellSelectDocs(item, index)"
                  ></v-select>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                  <v-select
                    v-model="docBuyer[index]"
                    :items="stepsBuyer"
                    :item-text="'name'"
                    :item-value="'value'"
                    multiple
                    dense
                    attached
                    outlined
                    @blur="buySelectDocs(item, index)"
                  ></v-select>
                </v-col>
              </v-row>

              <v-divider style="padding-bottom: 50px"></v-divider>

              <v-row>
                <v-label>Access</v-label>
              </v-row>
              <br /><br />
              <v-row>
                <v-label> Access till Mina sidor för detta objekt </v-label>
                <br /><br />
              </v-row>
              <v-row>
                <label class="switch">
                  <input
                    @change="changeAccess"
                    :checked="setAccessSeller"
                    type="checkbox"
                    id="seller"
                  />
                  <div class="slider round"></div>
                </label>
                <span style="padding-left: 20px; padding-right: 30px">
                  <v-label for="seller">Säljare</v-label></span
                >

                <label class="switch">
                  <input
                    :checked="setAccessBuyer"
                    @change="changeAccess"
                    type="checkbox"
                    id="buyer"
                  />
                  <div class="slider round"></div>
                </label>
                <span style="padding-left: 20px">
                  <v-label for="buyer">Köpare</v-label></span
                >
              </v-row>
              <v-row justify="end" align="center">
                <br />
                <v-btn @click="manualSync()" text>
                  <v-icon :class="{ spin: this.syncIcon }"
                    >autorenew</v-icon
                  ></v-btn
                >
                <v-label @click="manualSync()" style="padding-top: 15px"
                  >Uppdatera från mäklarsystemet</v-label
                >
              </v-row>
            </div>
          </v-row>

          <!--   </v-row>
     </v-col>-->
        </v-card>
      </v-row>
    </v-container>

    <!-- dialog skicka sms  -->
    <v-dialog v-model="smsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="boxText">
            Vill du skicka inloggningslänk till {{ this.smsName }}?
          </span>
        </v-card-title>

        <v-card-text>
          <span class="boxText">
            Du kan ändra texten i meddelandet. Länken och dina kontaktuppgifter
            läggs automatiskt till.
          </span>
          <br />
          <br />
          <v-textarea dense rows="2" outlined v-model="msgText"> </v-textarea>
          <br />
          <v-row> </v-row>
          <v-row>
            <v-btn @click="copyLink()" text>
              <v-icon>mdi-content-copy</v-icon>Kopiera länken</v-btn
            >
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="smsDialog = false" outlined>Skicka inte</v-btn>
          <v-btn @click="sendMsg('mail')" outlined>Skicka mail</v-btn>
          <v-btn @click="sendMsg('sms')" outlined>Skicka SMS</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
  </div>
</template>

<script>
import mixin from '@/mixin.js';
//import azureApi from '@/api/azureApi.js';
//import rest2Api from '@/api/rest2Api.js';
//import { COOKIE_NAME_SESSION } from '@/constants.js';
//import cloneDeep from 'lodash/cloneDeep';
import Description from '@/components/description.vue';
import get from 'lodash/get';
import { last } from 'lodash';
export default {
  name: 'MyPages',
  mixins: [mixin],
  data() {
    return {
      docSeller: [],
      docBuyer: [],
      smsDialog: false,
      smsName: '',
      smsNumber: '',
      smsLink: '',
      smsText: '',
      loadingPage: true,
      loginUrl: '',
      syncIcon: false,
      //   setAccessSeller: true,
      //  setAccessBuyer: true,
      header: 'Administrera Mina sidor',
      description:
        'Här kan du bestämma vilka dokument som ska synas i Mina sidor, samt skicka ut inloggningslänkar',
      msgText: 'Hej! \nHär kommer en inloggningslänk till Mina sidor:',

      stepsSeller: [
        { name: 'Bostaden', value: 'EstateData' },
        { name: 'Förberedelser', value: 'Preparations' },
        { name: 'Marknadsföring', value: 'Marketing' },
        { name: 'Visning & Budgivning', value: 'ViewingBidding' },
        { name: 'Kontrakt', value: 'Contract' },
        { name: 'Handpenning', value: 'DownPayment' },
        { name: 'Tillträde', value: 'Entry' },
        { name: 'Efter tillträde', value: 'AfterEntry' },
      ],
      stepsBuyer: [
        { name: 'Bostaden', value: 'EstateData' },
        { name: 'Kontrakt', value: 'Contract' },
        { name: 'Handpenning', value: 'DownPayment' },
        { name: 'Tillträde', value: 'Entry' },
        { name: 'Efter tillträde', value: 'AfterEntry' },
      ],
    };
  },

  watch: {
    documents() {
      this.loadDocs();
    },
    estateAddress() {
      this.loadingPage = false;
    },
    doubleCheckLoading() {
      this.loadingPage = false;
    },
    error(val) {
      if (val && val.status == 0) {
        (this.loadingPage = false),
          this.alertDialogNoCancel(
            'Fel',
            'Kan inte hämta bostaden: ' + val.error,

            () => console.log('user ok')
          );
      }
    },
  },
  components: { Description },
  beforeMount() {
    if (this.estateAddress) this.loadingPage = false;
  },
  mounted() {
    this.loadDocs();
  },
  computed: {
    // ...mapGetters({
    //   verifiedBid: 'azurestore/getVerifiedBidding',
    // }),

    setAccessBuyer() {
      var settings = get(this.$store.state.azurestore, 'estate.Settings', []);
      if (
        settings.VisibilityForBuyer == 'Hide' ||
        settings.VisibleForBuyer == false
      ) {
        return false;
      } else return true;
    },
    setAccessSeller() {
      var settings = get(this.$store.state.azurestore, 'estate.Settings', []);
      if (
        settings.VisibilityForSeller == 'Hide' ||
        settings.VisibleForSeller == false
      ) {
        return false;
      } else {
        return true;
      }
    },

    estateAddress() {
      var address = get(
        this.$store.state.azurestore,
        'estate.Address.StreetAddress',
        ''
      );
      if (address) address = address.toUpperCase();
      return address;
    },
    doubleCheckLoading() {
      return get(this.$store.state.azurestore, 'estate.BrokerUid', null);
    },
    error() {
      return get(this.$store.state.azurestore, 'estate', '');
    },
    tokenExpired() {
      return get(this.$store.state.azurestore, 'serviceOrder.hasExpired', '');
    },

    sellers() {
      var getSellers = get(this.$store.state.azurestore, 'estate.Sellers', []);
      return getSellers;
    },
    buyers() {
      var getBuyers = get(this.$store.state.azurestore, 'estate.Buyers', []);
      return getBuyers;
    },

    documents() {
      var getDocs = get(this.$store.state.azurestore, 'estate.Documents', []);
      // filtrera bort vitecs interna dokument de presenterar sig som zip
      getDocs = getDocs.filter(
        (r) => get(r, 'ContentType', '').indexOf('zip') == -1
      );
      return getDocs;
    },
    estateSettings() {
      var getDocs = get(this.$store.state.azurestore, 'estate.Settings', []);
      return getDocs;
    },

    theEstate() {
      return get(this.$store.state.azurestore, 'estate', null);
    },
    estateImage() {
      var estateID = get(
        this.$store.state.azurestore,
        'estate.ExternalIDs[0].Id',
        null
      );

      var image = get(this.$store.state.azurestore, 'imgUrl', null);
      if (estateID && !image) {
        var params = {
          estateID: estateID,
          org: this.org,
          source: this.theSource,
        };
        this.$store.dispatch('azurestore/getImageUrl', params);
      }

      return get(this.$store.state.azurestore, 'imgUrl', null);
    },
  },
  methods: {
    sellSelectDocs(item, index) {
      var steps = this.docSeller[index].join('%2C').trim();
      var path = item.Uid + '/Sell/' + steps;

      this.$store.dispatch('azurestore/makeDocAvailable', path);
    },

    buySelectDocs(item, index) {
      var steps = this.docBuyer[index].join('%2C').trim();
      var path = item.Uid + '/Buy/' + steps;

      this.$store.dispatch('azurestore/makeDocAvailable', path);
    },

    changeAccess(e) {
      var obj = new Object();
      var visibility = 'Show';
      if (e.target.checked == false) visibility = 'Hide';

      if (e.target.id == 'buyer') {
        obj.VisibilityForBuyer = visibility;
      } else {
        obj.VisibilityForSeller = visibility;
      }

      this.$store
        .dispatch('azurestore/changeEstateSettings', obj)
        .then((res) => {
          if (res && res.status > 399) {
            this.alertDialogNoCancel(
              'Fel',
              'Något gick fel: ' + res.statusText,

              () => console.log('user ok')
            );
          }
        });
    },

    loadDocs() {
      //mappa docs, lite magisk dubbelmappning kryddad med filtrering ;-)
      this.docSeller = this.documents.map((d) =>
        get(d, 'Placeholders', [])
          .filter((s) => s.View === 'Sell' || s.View === '*')
          .map((p) => get(p, 'Step', ''))
      );

      //
      this.docBuyer = this.documents.map((d) =>
        get(d, 'Placeholders', [])
          .filter((b) => b.View === 'Buy' || b.View === '*')
          .map((p) => get(p, 'Step', ''))
      );
    },

    copyLink() {
      // Copy the text inside the text field
      //remove periods in the end of the link
      this.smsLink = this.smsLink.replace(/\.{2,}$/g, '');

      navigator.clipboard.writeText(this.smsLink);
    },

    alertDialog(headerText, bodyText, okFunc, cancelFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: true,
        confirmAction: () => {
          okFunc();
        },
        cancelAction: () => {
          cancelFunc();
        },
      });
    },

    alertDialogNoCancel(headerText, bodyText, okFunc) {
      this.$store.dispatch('infopingstore/showAlert', {
        show: true,
        headerText: headerText,
        bodyText: bodyText,
        showCancelButton: false,
        confirmAction: () => {
          okFunc();
        },
      });
    },

    async prepareSMS(item, type) {
      if (type == 'Buy' && this.setAccessBuyer == false) {
        this.alertDialogNoCancel(
          'Access avstängd',
          'Köparen kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
          () => console.log('user ok')
        );
      } else if (type == 'Sell' && this.setAccessSeller == false) {
        this.alertDialogNoCancel(
          'Access avstängd',
          'Säljaren kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
          () => console.log('user ok')
        );

        //kolla om länk finns och om det är samma kontakt
      } else if (
        this.smsNumber &&
        this.smsLink.includes(item.ExternalIDs[0].Id)
      )
        this.smsDialog = true;
      else {
        //annars hämta länk
        this.createLink(item, type).then((res) => {
          if (res) {
            this.smsLink = res;
            this.smsDialog = true;
          }
        });
      }
    },

    async createLink(item, type) {
      var estateUid = get(this.$store.state.azurestore, 'estate.Uid', []);
      var path = this.theSource + '/' + item.Uid + '/' + type + '/' + estateUid;
      var answer = await this.$store.dispatch('azurestore/getLoginUrl', path);
      if (answer) {
        const url = new URL(answer);
        this.loginUrl = 'https://' + url.host;
        this.smsNumber = item.Mobile;
        this.smsName = item.FirstName + ' ' + item.LastName;
        // this.smsDialog = true;
      } else {
        this.alertDialogNoCancel(
          'Fel',
          'Det gick inte att skapa inloggningslänk, försök igen',
          () => console.log('user ok')
        );
      }

      return answer;
    },

    sendMsg(preferredContact) {
      this.smsDialog = false;

      const parts = this.smsLink?.split('/');

      // Hämta uppgifter från länken
      const contactId = parts[4]; // "CSPERSON5EEQRF51SVT1IHOV"
      const estateId = parts[parts.length - 1]; // "CMBOLGH5HFA8878JOF913N8"
      const role = parts[5];
      //remove .. in the end of the link

      this.smsLink = this.smsLink.replace(/\.\.$/, '');

      var params = {
        estateId: estateId,
        contactId: contactId,
      };
      if (role == 'Sell') params.contactRole = 'Seller';
      else if (role == 'Buy') params.contactRole = 'Buyer';
      params.message = this.msgText + '\n\n' + this.smsLink;

      if (preferredContact == 'mail') {
        params.rules = [
          { Send: 'Email', When: 'ValidEmailExist' },
          { Send: 'Sms', When: 'ValidEmailNotExist' },
        ];
      } else if (preferredContact == 'sms') {
        params.rules = [
          { Send: 'Sms', When: 'ValidSmsExist' },
          { Send: 'Email', When: 'ValidSmsNotExist' },
        ];
      }

      this.$store.dispatch('azurestore/sendLoginLink', params).then((res) => {
        if (res == 'ok')
          this.alertDialogNoCancel(
            'Skickat',
            'Ett meddelande med inloggningslänk är skickat.',
            () => console.log('user ok')
          );
        else
          this.alertDialogNoCancel(
            'Något gick fel',
            'Meddelandet skickades inte. Försök igen eller kopiera länken och skicka den på annat sätt. Felkod: ' +
              res,
            () => console.log('user ok')
          );
      });
    },
    async loginAsUser(item, type) {
      if (item) {
        if (type == 'Buy' && this.setAccessBuyer == false) {
          this.alertDialogNoCancel(
            'Access avstängd',
            'Köparen kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
            () => console.log('user ok')
          );
        } else if (type == 'Sell' && this.setAccessSeller == false) {
          this.alertDialogNoCancel(
            'Access avstängd',
            'Säljaren kan inte logga in på Mina sidor, sätt på åtkomst längre ner på sidan',
            () => console.log('user ok')
          );
        } else if (item?.Ssn != null) {
          var estateUid = get(this.$store.state.azurestore, 'estate.Uid', []);
          var contactUid = get(item, 'Uid', '');

          if (!this.loginUrl) {
            await this.createLink(item, type);
          }

          if (this.loginUrl) {
            this.$store
              .dispatch('azurestore/createLogin', contactUid)
              .then((res) => {
                window.open(
                  this.loginUrl +
                    '/' +
                    type +
                    '/' +
                    estateUid +
                    '?access_token=' +
                    res
                );
              });
          }
        } else if (item.Ssn == null) {
          this.alertDialogNoCancel(
            'Personnummer saknas',
            'Har användaren personnummer i mäklarsystemet? Det går inte att simulera en inloggning utan personnummer',
            () => console.log('user ok')
          );
        }
      } else {
        this.alertDialogNoCancel(
          '',
          'Hittar inte användaren i mäklarsystemet',
          () => console.log('user ok')
        );
      }
    },
    manualSync() {
      this.syncIcon = true;
      var estate = this.theEstate;
      estate.manualSync = 'doTheSync';
      this.$store.dispatch('azurestore/localEstate', estate).then((res) => {
        if (res && res.status == 0) {
          this.alertDialogNoCancel(
            '',
            'Kan inte hämta bostaden: ' + res.error,

            () => console.log('user ok')
          );
        }
        this.syncIcon = false;
      });
    },
  },
};
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #323232;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #999;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(45px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: 'AV';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: 'PÅ';
}

/*--------- END --------*/
.spin {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
</style>
