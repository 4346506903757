<template>
  <div style="width: 100%">
    <!--   Users -->

    <v-container>
      <v-card flat class="eventCard">
        <span class="topBroker">Toppmäklare</span><br />
        <br />
        <span style="padding-left: 10px"
          >Högst NPS där antal svar överstiger medelvärdet</span
        >
        <v-btn text @click="helpDialog = true"
          ><v-icon>mdi-help-circle-outline</v-icon></v-btn
        >
        <br />
        <br />
        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="translatedData"
          density="compact"
          @click:row="rowClick"
          no-data-text="Inte tillräckligt med svar inom angiven tidsperiod"
          hide-default-footer
          disable-pagination
        ></v-data-table>
      </v-card>
      <br />
      <v-card flat class="eventCard">
        <span class="topBroker">Alla mäklare</span><br />

        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="translatedDataAllUsers"
          density="compact"
          @click:row="rowClick"
          no-data-text="Inte tillräckligt med svar inom angiven tidsperiod"
          :footer-props="{
            'items-per-page-text': 'Svar per sida',
          }"
        ></v-data-table>
      </v-card>
    </v-container>

    <!-- dialog visa event mobilvy funkar ej obs -->
    <v-dialog
      v-model="eventDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="$vuetify.breakpoint.smAndUp ? 800 : '20vh'"
    >
      <v-card class="eventDialog">
        <v-card-text>
          <span class="boxText">
            <br />
            <b>Namn:</b> {{ this.currentEvent.BrokerName }}
            <br />
          </span>
          <span class="boxText">
            <br />
            <b>Kontor:</b> {{ this.currentEvent.CustomerName }}
            <br />
          </span>
          <br />
          <br />
          <span class="boxText">
            <b>NPS-betyg:</b> {{ this.currentEvent.Nps }}
          </span>
          <br />
          <br />
          <span class="boxText">
            <b>Antal svar:</b> {{ this.currentEvent.Cnt }}
          </span>
          <br />
          <br />
          <!-- funkar inte hittar inte arrayen?? 
            <span class="boxText">
            <b>Svar:</b> {{ this.currentEvent.NpsLst[0] }}
          </span>
          <span class="boxText">
            <b></b> {{ this.currentEvent.NpsLst[1] }}
          </span> -->
          <br />
          <br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeComment()" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
    <!-- dialog visa event mobilvy funkar ej obs -->
    <v-dialog v-model="helpDialog" max-width="400">
      <v-card class="eventDialog">
        <v-card-text>
          <span class="boxText">
            <br />
            <b>Hur vi räknar ut toppmäklare</b>
            <br />
            <br />
          </span>
          <span class="boxText">
            -Vi listar hur många svar alla mäklare fått.
            <br />
          </span>
          <span class="boxText">
            -Plockar ut 25% av flest antal svar och räknar ut ett medelvärde.
            <br />
          </span>
          <span class="boxText">
            -De mäklare som fått fler svar än medelvärdet rankas efter
            NPS-betyg.
          </span>
          <span class="boxText">
            <br />
            <br />
            Detta görs för att en mäklare med ett inkommet svar inte ska rankas
            högre än en mäklare med 10st 10:or och en 9:a.
            <br />
          </span>
          <br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="helpDialog = false" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--slut-->
  </div>
</template>
<script>
import mixin from '@/mixin.js';

import get from 'lodash/get';
export default {
  name: 'NPS-users',
  mixins: [mixin],
  data() {
    return {
      headers: [
        { text: 'Namn', value: 'BrokerName' },
        { text: 'Kontor', value: 'CustomerName' },
        { text: 'Antal svar', value: 'Cnt' },
        { text: 'NPS-betyg', value: 'Nps' },
      ],
      eventDialog: false,
      currentEvent: [],
      helpDialog: false,
    };
  },
  computed: {
    users() {
      return get(this.$store.state.infopingstore, 'statsData.TopBrokers', []);
    },
    translatedData() {
      return this.users.map((t) => {
        return {
          ...t,
          Nps: this.roundUp(t.Nps),
        };
      });
    },
    allUsers() {
      return get(this.$store.state.infopingstore, 'statsData.AllBrokers', []);
    },
    translatedDataAllUsers() {
      return this.allUsers.map((t) => {
        return {
          ...t,
          Nps: this.roundUp(t.Nps),
        };
      });
    },
  },
  watch: {},
  components: {},
  mounted() {},
  methods: {
    roundUp(score) {
      return Math.round(score * 10) / 10;
    },
    rowClick(item) {
      this.currentEvent = item;
      this.eventDialog = true;
    },
    closeComment() {
      this.eventDialog = false;
      this.currentEvent = [];
      this.commentSurveyType = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eventCard {
  width: 100%;
  padding: 30px;
}

.eventDialog {
  padding: 20px;
}

.topBroker {
  font-size: 20px;
  font-weight: bolder;
  padding-left: 10px;
}
</style>
