import get from 'lodash/get';

export default {
  //hämtar alla orgs under vald access, tex share_brokers, report...
  access_orgs: (state) => (theaccess) => {
    var access = get(state, 'access', []);

    var reports = access.filter(function (obj) {
      return obj.name.includes(theaccess);
    });


    var orgs = [];
    if (reports.length > 0) {
      reports.forEach((reportOrgs) => {
        reportOrgs.orgs.forEach((element) => {
          var obj = {
            name: element.name,
            org_key: element.org_key,
            //lägg till kontor här
          };
          // om orgs inte innehåller org_key lägg till objektet vi skapat
          if (!orgs.some((item) => item.org_key === obj.org_key))
            orgs.push(obj);
        });
      });
    }

    // sortera array med objekt
    orgs.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    return orgs;
  },

  broker_offices: (state) => (access, org_key) => {
    var accessList = get(state, 'access', []);

    var accessObject = accessList.filter(function (obj) {
      return obj.name.includes(access);
    });

    var offices = [];
    if (accessObject.length > 0) {
      accessObject.forEach((org) => {
        org.orgs.forEach((org2) => {
          if (org2.org_key == org_key)
            org2.customers.forEach((office) => {
              var obj = {
                name: office.name + ' - ' + office.customerId,
                customerId: office.customerId,
              };
              // om orgs inte innehåller org_key lägg till objektet vi skapat
              if (!offices.some((item) => item.customerId === obj.customerId))
                offices.push(obj);
            });
        });
      });
    }

    // sortera array med objekt
    offices.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    return offices;
  },
  statsCustomers: (state) => {
    return get(state, 'statsStructure.Companies', []);
  },
  statsOffices: (state) => (customer) => {
    if (customer == null) return [];
    var findCustomer = get(state, 'statsStructure.Companies', []);
    var data = findCustomer.filter((c) => c.Org == customer);
    var offices = [];
    if (data.length > 0) {
      data[0].Customers.forEach((office) => {
        var obj = {
          Name: office.Name + ', ' + office.Id,
          Id: office.Id,
        };
        //om kontoret inte redan finns i listan lägg till det
        if (!offices.some((item) => item.Id === obj.Id)) offices.push(obj);
      });
      //sortera listan
      offices.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

      // lägg till "Alla kontor" om det är fler än ett
      if (offices && offices.length > 1)
        offices.unshift({ Name: 'Alla kontor', Id: '000000' });
    }

    return offices;
    // }
  },
  // statsUsers: (state) => (customer, office) => {
  //   console.log('user', customer, office);
  //   if (office == null) return [];
  //   //find customer
  //   var findCustomer = get(state, 'statsStructure.Companies', []);
  //   //find office
  //   var offices = findCustomer.filter((c) => c.Org == customer);
  //   console.log('offices', offices);
  //   //find users
  //   var data = offices[0].Customers.filter((u) => u.Id == office);
  //   var brokers = [];
  //   if (data.length > 0) {
  //     data.forEach((broker) => {
  //       var obj = {
  //         Name: broker.Name,
  //         Id: broker.Id,
  //       };
  //       //om kontoret inte redan finns i listan lägg till det
  //       if (!brokers.some((item) => item.brokerId === obj.brokerId))
  //         brokers.push(obj);
  //     });

  //     //sortera listan

  //     // lägg till "Alla mäklare" om det är fler än ett
  //     if (brokers && brokers.length > 1)
  //       brokers.unshift({ Name: 'Alla mäklare', Id: '000000' });
  //   }
  //   return brokers;
  // },
};
