import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Viewing from '../components/settingsViewing.vue';
import BrokerWeb from '../components/settingsBrokerWeb.vue';
import Reports from '../components/settingsReportsLightCard.vue';
import MyPages from '../components/settingsMyPages.vue';
//import BestComment from '../components/bestComment.vue';
import NPS_EO from '../components/NPS_EO.vue';
import NPS from '../components/npsPage.vue';
import Stats from '../components/stats.vue';
import Bidding from '../components/bidding.vue';
import Shortcuts from '../components/shortcuts.vue';
import Smartspec from '../components/smartspec.vue';

//import Leads from "../components/settingsReports.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/viewing',
        name: 'Viewing',
        component: Viewing,
      },

      {
        path: '/brokerweb',
        name: 'BrokerWeb',
        component: BrokerWeb,
      },
      {
        path: '/mypages',
        name: 'MyPages',
        component: MyPages,
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports,
      },
      {
        path: '/nps_eo',
        name: 'NpsEO',
        component: NPS_EO,
      },
      {
        path: '/nps',
        name: 'Nps',
        component: NPS,
      },
      {
        path: '/stats',
        name: 'Stats',
        component: Stats,
      },
      {
        path: '/bidding',
        name: 'Bidding',
        component: Bidding,
      },
      {
        path: '/shortcuts',
        name: 'Shortcuts',
        component: Shortcuts,
      },
      {
        path: '/smartspec',
        name: 'smartspec',
        component: Smartspec,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  //för att börja på bid nu i början
  {
    path: '*',
    name: 'Default',
    component: MyPages,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
