<template>
  <!--    :css-classes="cssClasses"-->
  <Doughnut
    :options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: Array,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        // labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#e6b400', '#E46651'], //#41B883', '#E46651', '#00D8FF
            data: this.data ?? [100, 0, 0],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        spacing: 3,
      },
    };
  },
  watch: {
    backgroundColor(val) {
      if (val) {
        this.chartData = {
          datasets: [
            {
              backgroundColor: val,
              data: this.data,
            },
          ],
        };
      }
    },
    data(val) {
      if (val) {
        this.chartData = {
          datasets: [
            {
              backgroundColor: this.backgroundColor, //#41B883', '#E46651', '#00D8FF
              data: val,
            },
          ],
        };
      }
    },
  },
  mounted() {
    const tmp = this.chartData;
    this.chartData = tmp;
  },
};
</script>
