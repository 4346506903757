<template>
  <div style="width: 100%">
    <!--   Events -->
    <v-container>
      <v-card flat class="eventCard">
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Sök"
            single-line
            hide-details
            style="margin: 10px; max-width: 250px"
          ></v-text-field>
        </v-row>
        <br />
        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="translatedItems"
          :search="search"
          density="compact"
          @click:row="rowClick"
          no-data-text="Inga svar"
          :footer-props="{
            'items-per-page-text': 'Svar per sida',
          }"
        ></v-data-table>
      </v-card>
    </v-container>

    <!-- dialog visa event mobilvy funkar ej obs -->
    <v-dialog
      v-model="eventDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="$vuetify.breakpoint.smAndUp ? 800 : '20vh'"
    >
      <v-card class="eventDialog">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b>Fråga:</b> {{ this.currentEvent.Question }}
                <br />
              </span>
              <span class="boxText">
                <br />
                <b>Betyg:</b> {{ this.currentEvent.Rating }} (av
                {{ this.currentEvent.Scale }} )
                <br />
              </span>

              <br />
              <br />
              <span class="boxText">
                <b>Objekt:</b> {{ this.currentEvent.StreetAddress }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Part:</b> {{ this.currentEvent.ContactRole }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Namn:</b> {{ this.currentEvent.ContactName }}
              </span>
              <br />
              <br />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b> Datum:</b> {{ this.currentEvent.AnswerDate }}
                <br />
              </span>

              <br />
              <br />

              <span v-if="this.currentEvent.Extra" class="boxText">
                <br />
                <b>Info:</b> {{ this.currentEvent.Extra }}
                <br />
              </span>
              <span v-else>
                <br />
                <br />
                <br />
              </span>

              <br />
              <br />
              <span class="boxText">
                <b>Mäklare:</b> {{ this.currentEvent.BrokerName }}
              </span>
              <br />
              <br />
              <br />
              <br />

              <br />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeComment()" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- slut -->
  </div>
</template>
<script>
import mixin from '@/mixin.js';

import get from 'lodash/get';
export default {
  name: 'NPS-items',
  mixins: [mixin],
  data() {
    return {
      headers: [
        { text: 'Namn', value: 'ContactName' },
        { text: 'Objekt', value: 'StreetAddress' },
        { text: 'Fråga', value: 'Question' },
        { text: 'Betyg', value: 'Rating' },
      ],
      eventDialog: false,
      currentEvent: [],
      search: '',
    };
  },
  computed: {
    items() {
      return get(this.$store.state.infopingstore, 'statsData.items', []);
    },
    translatedItems() {
      return this.items.map((t) => {
        return {
          ...t,
          ContactRole: this.translateContactRole(t.ContactRole),
        };
      });
    },
  },
  watch: {},
  components: {},
  mounted() {},
  methods: {
    translateContactRole(item) {
      switch (item) {
        case 'ViewingParticipant':
          return 'Visningsdeltagare';
        case 'Buyer':
          return 'Köpare';
        case 'Seller':
          return 'Säljare';
        default:
          return item;
      }
    },

    rowClick(item) {
      this.currentEvent = item;
      this.eventDialog = true;
    },
    closeComment() {
      this.eventDialog = false;
      this.currentEvent = [];
      this.commentSurveyType = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eventCard {
  width: 100%;
  padding: 30px;
}

.eventDialog {
  padding: 20px;
}
</style>
