<template>
  <div>
    <div class="description desktop-view">
      <div class="description-content">
        <div class="left-content">
          <div v-if="isLoading" class="spinner-container">
            <Spinner :color="spinnerColor" class="spinner-overlay" />
          </div>
          <img v-if="!isLoading" :src="url" @error="imgErrorHandler" />
          <div>
            <span class="title">{{ header }}</span>
            <br />
            <span class="text">{{ description }}</span>
          </div>
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text @click="goToBrokerWeb()">
              <img v-if="description.includes('dokument')" class="houses" />
            </v-btn>
          </template>
          <span>Lista alla objekt</span>
        </v-tooltip>
      </div>
    </div>
    <!-- Mobile View -->
    <div class="mobile-view">
      <img class="mobile-img" :src="url" />
      <div class="mobile-text">
        <span class="title">{{ header }}</span>
        <br />
        <span class="text">{{ description }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import get from 'lodash/get';
import Spinner from '@/components/spinners/dottsOnRow.vue';
export default {
  name: 'ModuleDescription',
  props: { header: String, description: String, url: String },
  mixins: [mixin],
  data() {
    return {
      isLoading: true,
      spinnerColor: '#e1e1e1',
    };
  },
  beforeMount() {},
  watch: {
    url: {
      immediate: true,
      handler(newUrl) {
        if (newUrl) {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      },
    },
  },
  components: { Spinner },

  methods: {
    goToBrokerWeb() {
      if (get(this.$store.state.infopingstore, 'localUser', 'false') == false) {
        this.$store
          .dispatch(
            'azurestore/getUserFromSession',
            get(this.$store.state.infopingstore, 'session', null)
          )
          .then(() => {
            this.$store.dispatch('infopingstore/addLocalUser');
          });
      }

      this.$router.push({
        path: '/brokerWeb',
      });
    },
    imgErrorHandler(e) {
      console.log('error target', e);

      const target = e.target;
      this.isLoading = false;
      //  if (target.src?.indexOf('mypages') > 'img') return;
      // if (target.src?.indexOf('bidding') > 'img') return;
      console.log('error', e);
      target.src =
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_noimage_wtext.jpg';
      target.onerror = null; //prevent possible infinit loop if above url is not found
    },
  },
};
</script>

<style scoped></style>
