<template>
  <Bar
    :options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
    indexAxis: {
      type: String,
      default: () => 'x',
    },
    barThickness: {
      type: Number,
      default: () => 6,
    },
  },
  data() {
    return {
      chartOptions: {
        indexAxis: this.indexAxis,
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          title: { display: false, text: '' },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              color: '#000000',
            },
          },
        },
        barThickness: this.barThickness,
        borderRadius: 5,
      },

      chartData: {
        datasets: [
          {
            data: [],
          },
        ],
      },
      show: false,
    };
  },
  watch: {
    data(val) {
      if (val) {
        this.chartData = {
          datasets: this.data,
        };
        // datasets: [
        //   {
        //     backgroundColor: '#f87979',
        //     data: val.map((row) => ({
        //       x: row.Count,
        //       y: row.Name,
        //     })),
        //   },
        //   {
        //     backgroundColor: '#486659',
        //     data: val.map((row) => ({
        //       x: row.Count + 25,
        //       y: row.Name,
        //     })),
        //   },
        // ],
        //};
        this.chartOptions.plugins.title.text = this.title;
        this.chartOptions.plugins.legend.display = true;
      }
    },
  },
};
</script>
