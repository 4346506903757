<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />

      <template>
        <v-expansion-panels>
          <v-expansion-panel v-for="(estate, i) in estates" :key="i">
            <v-expansion-panel-header>
              {{ estate.address }} <v-spacer></v-spacer> 2/5 Budgivare har läst
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Sök"
                single-line
                hide-details
                style="margin: 10px; max-width: 250px"
              ></v-text-field>
              <v-data-table
                :value="selected"
                class="row-pointer"
                item-value="id"
                item-key="id"
                :headers="headers"
                :search="search"
                :items="log"
                density="compact"
                show-select
                @click:row="rowClick"
                no-data-text="Inga loggar"
                :footer-props="{
                  'items-per-page-text': 'Rader per sida',
                }"
                @update:select="updateSelected"
              >
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-container>
    <!-- dialog visa detaljer 
    <v-dialog
      v-model="eventDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="$vuetify.breakpoint.smAndUp ? 800 : '20vh'"
    >
      <v-card class="eventDialog">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b>Aktivitet:</b> {{ this.currentEvent.What }}
                <br />
              </span>
              <span class="boxText">
                <br />
                <b>Typ:</b> {{ this.currentEvent.Type }}
                <br />
              </span>
              <span v-show="this.currentEvent.Value" class="boxText">
                <br />
                <b>Vad:</b> {{ this.currentEvent.Value }}
                <br />
              </span>
              <br />
              <br />

              <br />
              <br />

              <span class="boxText">
                <b>Objekt:</b> {{ this.currentEvent.StreetAddress }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Part:</b> {{ this.currentEvent.ContactRole }}
              </span>
              <br />
              <br />
              <span class="boxText">
                <b>Namn:</b> {{ this.currentEvent.ContactName }}
              </span>
              <br />
              <br />
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <span class="boxText">
                <br />
                <b> Datum:</b> {{ this.currentEvent.EventDate }}
                <br />
              </span>

              <br />
              <br />

              <span v-if="this.currentEvent.Extra" class="boxText">
                <br />
                <b>Info:</b> {{ this.currentEvent.Extra }}
                <br />
              </span>
              <span v-else>
                <br />
                <br />
                <br />
              </span>

              <br />
              <br />
              <span class="boxText">
                <b>Mäklare:</b> {{ this.currentEvent.BrokerName }}
              </span>
              <br />
              <br />
              <br />
              <br />

              <br />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeComment()" outlined>Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
slut -->
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import Description from '@/components/description.vue';
export default {
  name: 'Smartspec-logg',
  mixins: [mixin],
  data() {
    return {
      header: 'Smartspec',
      description: 'Logg över vem som läst och vilken version som lästs',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/printer.jpg',
      loadingPage: false,
      selected: [],
      headers: [
        { text: 'Vem', value: 'who' },
        { text: 'Status', value: 'status' },
        { text: 'Skickat', value: 'sent' },
        { text: 'Läst', value: 'read' },
        { text: 'Version', value: 'version' },
      ],
      log: [
        {
          who: 'Anna Danielsson',
          status: 'Budgivare',
          sent: '2024-05-05',
          read: '2024-05-06',
          version: '1.01',
        },
        {
          who: 'Erik Hall',
          status: 'Deltagit på visning',
          sent: '2024-05-05',
          read: 'Inte läst',
          version: '1.01',
        },
      ],
      search: '',
    };
  },
  computed: {
    estates() {
      return [
        { estateId: 1, address: 'Väpnargatan 2' },
        { estateId: 2, address: 'Kungsgatan 66' },
      ];
    },
  },
  methods: {
    rowClick(item) {
      console.log('radklick', item);
    },
    updateSelected(items) {
      this.selected = items; // Uppdatera valda rader
    },
  },
  components: { Description },
};
</script>
<style></style>
